import { createSlice } from "@reduxjs/toolkit";


const pcDetails = createSlice({
    name: "pcDetails",
    initialState: {
        pcDetails: {

        },
        isFetched: false
    },
    reducers: {
        setPcDetails: (state, action) => {
            state.pcDetails = action.payload
            state.isFetched = true
        },
    }
})

export const { setPcDetails } = pcDetails.actions
export default pcDetails.reducer