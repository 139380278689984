import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../helper/api';
import Select from "react-select"
import { toast } from 'react-toastify';
import { setPcDetails } from '../../redux/pcDetails';
import { CircularProgress } from '@mui/material';

const Pcs = () => {
    const { pcDetails } = useSelector(state => state.pcDetails)
    const dispatch = useDispatch();
    const [currentPc, setCurrentPc] = useState({
        fingerprintId: "",
        pcName: "",
        branchId: ""
    });
    const [selectedFingerprint, setSelectedFingerprint] = useState(null)
    const [branches, setBranches] = useState([])
    const [branchOverlay, setBranchOverlay] = useState(false)
    const [nameOverlay, setNameOverlay] = useState(false);
    const [existingName, setExistingName] = useState("")
    const [name, setName] = useState('')
    const api = new Api();
    const [retry, setRetry] = useState(false);
    const [pcList, setPcList] = useState([]);

    const baseStyles = "px-4 py-2 rounded-lg font-medium transition-all mr-4 mt-2";
    const variants = {
        default: "bg-red-600 text-white hover:bg-red-700",
        outline: "border border-gray-400 text-gray-700 hover:bg-gray-100",
    };

    useEffect(() => {
        if (pcDetails.fingerprintId) {
            setCurrentPc(pcDetails)
        }
    }, [pcDetails.fingerprintId])

    useEffect(() => {
        fetchAllPcs()
    }, [retry])

    useEffect(() => {
        fetchAllBranch();
    }, [])

    const fetchAllPcs = async () => {
        try {
            const { data, status } = await api.getAllPc();

            if (status === 200) {
                setPcList(data.data)
                let pcExist = data.data.find((data) => data.fingerprintId === currentPc.fingerprintId)

                if (pcExist) {
                    dispatch(setPcDetails({
                        fingerprintId: pcExist.fingerprintId,
                        branchId: pcExist?.branchId,
                        pcName: pcExist?.pcName
                    }))
                    setCurrentPc({
                        fingerprintId: pcExist?.fingerprintId,
                        branchId: pcExist?.branchId,
                        pcName: pcExist?.pcName
                    })
                }
            }
        } catch (err) {

        }
    }

    const fetchAllBranch = async () => {
        try {
            const { data, status } = await api.fetchAccount({}, { page: 1, quantity: 1000 })

            if (status === 200) {
                setBranches(data.accountDetails)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const assignBranch = (fingerprintId) => {
        setSelectedFingerprint(fingerprintId)
        setBranchOverlay(true)
    };

    const changeName = (fingerprintId, name) => {
        setSelectedFingerprint(fingerprintId)
        setNameOverlay(true)
        setExistingName(name)
    };

    const closeBranchOverlay = () => {
        setSelectedFingerprint(null)
        setBranchOverlay(false)
    }

    const closeNameOverlay = () => {
        setSelectedFingerprint(null)
        setNameOverlay(false)
    }

    const handleDelete = async (fingerprintId) => {
        try {
            const { data, status } = await api.deletePcDetails({
                fingerprintId
            })

            if (status === 200) {
                toast.success("Delete Successfully")
                setRetry(!retry);
            }
        } catch (err) {
            console.log(err)
            toast.error("Some error occurred")
        }
    }

    return (
        <div className="p-6 space-y-6">
            <h1 className="text-2xl font-bold">PC Details</h1>
            {currentPc ? (
                <div className="border p-4 rounded-lg shadow-md">
                    <p><strong>Fingerprint ID:</strong> {currentPc?.fingerprintId}</p>
                    <p><strong>PC Name:</strong> {currentPc?.pcName}</p>
                    <p><strong>Branch:</strong> {branches.find((branch) => branch._id === currentPc.branchId)?.name || currentPc?.branchId || "Not Assigned"}</p>
                    <button className={`${baseStyles} ${variants["default"]}`} onClick={() => assignBranch(currentPc?.fingerprintId)} variant="outline">Assign Branch</button>
                    <button className={`${baseStyles} ${variants["default"]}`} onClick={() => changeName(currentPc?.fingerprintId, currentPc.pcName)} variant="outline">Change Name</button>
                    <button className={`${baseStyles} ${variants["default"]}`} onClick={() => handleDelete(currentPc?.fingerprintId)} variant="outline">Delete Pc</button>
                </div>
            ) : (
                <p>Loading current PC details...</p>
            )}

            <h2 className="text-xl font-semibold mt-6">All PCs</h2>
            <div className="space-y-4">
                {pcList.length > 0 ? (
                    pcList.map((pc) => (
                        <div key={pc.fingerprintId} className="border p-4 rounded-lg shadow-md">
                            <p><strong>PC Name:</strong> {pc.pcName}</p>
                            <p><strong>Fingerprint ID:</strong> {pc.fingerprintId}</p>
                            <p><strong>Branch:</strong> {branches.find((branch) => branch._id === pc.branchId)?.name || pc.branchId || "Not Assigned"}</p>
                            <button className={`${baseStyles} ${variants["default"]}`} onClick={() => assignBranch(pc?.fingerprintId)} variant="outline">Assign Branch</button>
                            <button className={`${baseStyles} ${variants["default"]}`} onClick={() => changeName(pc?.fingerprintId, pc?.pcName)} variant="outline">Change Name</button>
                            <button className={`${baseStyles} ${variants["default"]}`} onClick={() => handleDelete(pc?.fingerprintId)} variant="outline">Delete Pc</button>
                        </div>
                    ))
                ) : (
                    <p>Loading PC list...</p>
                )}
            </div>
            {
                branchOverlay &&
                <div style={{ width: "100%" }}>
                    <OverLayBranch retry={retry} setRetry={setRetry} branches={branches} handleClose={closeBranchOverlay} fingerprintId={selectedFingerprint} />
                </div>
            }
            {
                nameOverlay &&
                <div style={{ width: "100%" }}>
                    <OverLayPcName existingName={existingName} retry={retry} setRetry={setRetry} handleClose={closeNameOverlay} fingerprintId={selectedFingerprint} />
                </div>
            }
        </div>
    )
}

const OverLayBranch = ({ branches, fingerprintId, handleClose, retry, setRetry }) => {
    const baseStyles = "px-4 py-2 rounded-lg font-medium transition-all mr-4 mt-2";
    const variants = {
        default: "bg-red-600 text-white hover:bg-red-700",
        outline: "border border-gray-400 text-gray-700 hover:bg-gray-100",
    };
    const [loading, setLoading] = useState(false);
    const api = new Api();

    const [branchSelected, setBranchSelected] = useState(null)

    const saveBranch = async () => {
        if (branchSelected) {
            try {
                setLoading(true);
                const { data, status } = await api.updatePcDetails({
                    fingerprintId,
                    branchId: branchSelected
                })

                if (status === 200) {
                    toast.success("Saved successfully")
                    setRetry(!retry)
                    handleClose()
                }
            } catch (err) {
                console.log(err)
                toast.error("Unable to save")
            } finally {
                setLoading(false)
            }
        } else {
            handleClose()
        }
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 absolute z-50">
            <div
                className="bg-white p-6 rounded-2xl shadow-xl text-center space-y-4"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
            >
                <Select
                    options={[{ value: "all", label: "All" }, ...branches?.map((account) => {
                        return {
                            value: account?._id,
                            label: account?.name
                        }
                    })]}
                    value={
                        branchSelected === "all" ? { value: "all", label: "All" } : branchSelected ?
                            {
                                value: branches?.find((branch) => branch._id === branchSelected)?._id,
                                label: branches?.find((branch) => branch._id === branchSelected)?.prefix
                            }
                            : ""
                    }
                    placeholder="Select branch"
                    styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginRight: "8px", width: "100%", zIndex: 10 }) }}
                    onChange={(value) => setBranchSelected(value.value)}
                />
                <div className="flex justify-center gap-4">
                    <button className={`${baseStyles} ${variants["default"]}`} onClick={handleClose} variant="outline">Cancel</button>
                    <button className={`${baseStyles} ${variants["outline"]}`} onClick={saveBranch} variant="default">
                        {
                            loading ? <CircularProgress size={18} /> : "Save"
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

const OverLayPcName = ({ fingerprintId, handleClose, retry, setRetry, existingName }) => {
    const baseStyles = "px-4 py-2 rounded-lg font-medium transition-all mr-4 mt-2";
    const variants = {
        default: "bg-red-600 text-white hover:bg-red-700",
        outline: "border border-gray-400 text-gray-700 hover:bg-gray-100",
    };
    const [loading, setLoading] = useState(false);
    const api = new Api();

    const [name, setName] = useState("")

    useEffect(() => {
        setName(existingName)
    }, [existingName])

    const saveBranch = async () => {
        if (name) {
            try {
                setLoading(true);
                const { data, status } = await api.updatePcDetails({
                    fingerprintId,
                    pcName: name
                })

                if (status === 200) {
                    toast.success("Saved successfully")
                    setRetry(!retry)
                    handleClose()
                }
            } catch (err) {
                console.log(err)
                toast.error("Unable to save")
            } finally {
                setLoading(false)
            }
        } else {
            handleClose()
        }
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 absolute z-50">
            <div
                className="bg-white p-6 rounded-2xl shadow-xl text-center space-y-4"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
            >
                <p>Pc Name:</p>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} style={{ border: "1px solid #000", padding: 10, outline: "none", borderRadius: 5 }} />
                <div className="flex justify-center gap-4">
                    <button className={`${baseStyles} ${variants["default"]}`} onClick={handleClose} variant="outline">Cancel</button>
                    <button className={`${baseStyles} ${variants["outline"]}`} onClick={saveBranch} variant="default">
                        {
                            loading ? <CircularProgress size={18} /> : "Save"
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Pcs