import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import Button from "../../Components/Shared/Buttons";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { API } from "../../config";
import PopoverMenu from "../../Components/Shared/Popover";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaRegFileExcel } from "react-icons/fa6";
import {
  DropdownSvg,
  FilterSvg,
  ResetFilterSvg,
  UserSvg,
} from "../../Components/Shared/UserSvg";
import { useReactToPrint } from "react-to-print";
import Buttons from "../../Components/Shared/Buttons";
import { FiSearch } from "react-icons/fi";
import FilterCategory from "./FilterCategory";
import Api from "../../helper/api";
import Uploading from "../../Components/Shared/Uploading";
import { useSelector } from "react-redux";
import { isAllowed } from "../../helper/helper";

const AddNewTest = () => {
  const tableclasses = tableStyles();
  const api = new Api();
  const TOKEN = localStorage.getItem("logintoken");
  const [filter, setFilter] = useState({
    category: null,
    alphabets: null,
    sortBy: null,
  })
  const [isUploading, setIsUploading] = useState(false);
  const { data, isLoggedIn } = useSelector(state => state.user)

  const [filterData, setFilterData] = useState({
    category: [],
    alphabets: [],
    sortBy: null,
    search: ""
  })

  const navigate = useNavigate();

  //  set test data
  const [rows, setRows] = useState();

  // set name to filter
  const [name, SetName] = useState("");
  const [newData, setNewData] = useState(false);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState();
  const reportContent = useRef();

  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
    // onAfterPrint:alert("Pdf Downloaded")
  });

  //  fetch data
  const fetchData = async () => {
    const data = await axios.get(`${API}/gettestsubcategory/${page}/10?category=${JSON.stringify(filterData.category)}&alphabets=${JSON.stringify(filterData.alphabets)}&sortBy=${filterData.sortBy}&search=${filterData.search}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setRows(data?.data?.subTestCategory);
    setPageInfo(data?.data);
  };

  const handleReset = () => {
    setFilterData({
      category: [],
      alphabets: [],
      sortBy: null,
      search: ""
    })
  }

  useEffect(() => {
    fetchData();
  }, [newData, page, filterData.category, filterData.alphabets, filterData.sortBy, filterData.search]);

  const handleDelete = async (id) => {
    const data = await axios.delete(`${API}/deletetestsubcategory/${id}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    if (data?.data?.message === "Test  removed successfully") {
      setNewData(true);
      toast.success("Test removed successfully");
      setNewData(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-test/${id}`);
  };

  const filteredData = rows?.filter((item) =>
    item?.name?.toLowerCase().includes(name?.toLowerCase())
  );

  const setNextPage = () => {
    if (pageInfo?.currentPage > 0) {
      if (page === pageInfo?.totalPages) return;
      setPage(page + 1);
    }
  };

  const setPrevPage = () => {
    if (pageInfo.currentPage > 1) {
      setPage(page - 1);
    }
  };

  const handleSearch = (e) => {
    e.persist();

    setFilterData((prev) => {
      return { ...prev, search: e.target.value }
    })
  }

  const handleUpload = async (e) => {
    try {
      setIsUploading(true)
      console.log("file", e.target.files[0])

      if (e.target.files[0].type.includes(".spreadsheetml.sheet")) {
        const { data, status } = await api.uploadTestExcel(e.target.files[0])

        if (status === 200) {
          toast.success("Uploaded successfully")
          setIsUploading(false)
          setNewData(!newData);
        }
      } else {
        toast.error("Please upload excel file")
        setIsUploading(false)
      }

      setTimeout(() => {
        setIsUploading(false)
      }, 2500);
    } catch (err) {
      setIsUploading(false)
      console.log(err)
    }
  }

  return (
    <div className={tableclasses.root}>
      {
        isUploading &&
        <Uploading />
      }
      <FilterCategory
        filter={filter}
        filterData={filterData}
        setFilterData={setFilterData}
        setFilter={setFilter} />
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.h2}>Tests</div>
            <div className={tableclasses.specification}>
              {pageInfo?.totalItems} available test
            </div>
            <input type="file" id="excel" onChange={handleUpload} name="excel" style={{ display: "none" }} />
            <label htmlFor="excel">
              <div
                className={tableclasses.addButton}
                style={{ marginTop: "10px", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 600, cursor: "pointer" }}
              >
                <FaRegFileExcel style={{ fontSize: "16px", marginRight: "5px" }} />
                Import Excel
              </div>
            </label>
          </div>

          <div>
            {
              isAllowed(data.permissions, "TEST_ADD", data.role) &&
              <Button
                className={tableclasses.addButton}
                onClick={() => navigate("/register-new-test")}
              >
                <UserSvg /> &nbsp; Add new test
              </Button>
            }
          </div>
        </div>

        <div className={tableclasses.filterSearch}>
          <div>
            <Buttons className={tableclasses.filterButton1}>
              <FilterSvg />
            </Buttons>
            <Buttons className={tableclasses.filterButton2}>Filter By</Buttons>
            <Buttons
              onClick={(e) => setFilter((prev) => { return { ...prev, category: e.currentTarget } })}
              className={tableclasses.filterButton2}
            >
              Category
              <DropdownSvg />
            </Buttons>
            <Buttons
              onClick={(e) => setFilter((prev) => { return { ...prev, alphabets: e.currentTarget } })}
              className={tableclasses.filterButton2}
            >
              Albhabets
              <DropdownSvg />
            </Buttons>
            <Buttons
              onClick={(e) => setFilter((prev) => { return { ...prev, sortBy: e.currentTarget } })}
              className={tableclasses.filterButton2}>Sort By</Buttons>
            <Buttons
              onClick={handleReset}
              className={tableclasses.filterButton3}
            >
              <ResetFilterSvg />
              <span style={{ color: "#FF8743", marginLeft: 6, text: "center" }}>
                Reset FIlter
              </span>{" "}
            </Buttons>
            <Buttons className={tableclasses.printButton} onClick={generatePDF}>
              Print
            </Buttons>
          </div>

          <div className={tableclasses.searchContainer}>
            <FiSearch className={tableclasses.searchIcon} />
            <TextField
              className={tableclasses.searchField}
              placeholder="Search"
              variant="standard"
              size="small"
              value={filterData.search}
              onChange={handleSearch}
            />
          </div>
        </div>

        <Table className={tableclasses.table} ref={reportContent}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              <TableCell className={tableclasses.customHeadName}>
                Test ID
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Test name
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Unit
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Category
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Options
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                >
                  <div className={tableclasses.name}>
                    <div>{row.id}</div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.name}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.units}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.category?.map((sub) => sub?.name)}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div style={{
                    width: "250px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}>
                    {row?.options.map((map, index) => (
                      <span key={index}>
                        {map.code}|{map.option}{" "}
                        {index < row.options.length - 1 ? " " : ""}
                      </span>
                    ))}
                  </div>
                </TableCell>

                <TableCell className={tableclasses.customTableCell}>
                  <div>
                    <PopoverMenu
                      data={rows}
                      handleEdit={() => handleEdit(row._id)}
                      handleDelete={() => handleDelete(row._id)}
                      permission={data}
                      editId="TEST_EDIT"
                      deleteId="TEST_DELETE"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={tableclasses.pagination}>
          <div className={tableclasses.name}>
            Showing {rows?.length} of {pageInfo?.totalItems} entries
          </div>
          <div>
            <Button onClick={setPrevPage} className={tableclasses.pageButton}>
              Previous
            </Button>
            <Button className={tableclasses.numButton}>
              {pageInfo?.currentPage}
            </Button>
            {page < pageInfo?.totalPages && (
              <Button onClick={setNextPage} className={tableclasses.pageButton}>
                Next
              </Button>
            )}
          </div>
          {/* <div></div> */}
        </div>
      </div>
    </div>
  );
};

export default AddNewTest;

