import { createSlice } from "@reduxjs/toolkit";


const userSlice = createSlice({
    name: "user",
    initialState: {
        data: {
            email: null,
            role: null,
            permissions: [],
        },
        isLoggedIn: false,
    },
    reducers: {
        setUser: (state, action) => {
            state.data = action.payload
        },
        setLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        }
    }
})

export const { setUser, setLoggedIn } = userSlice.actions
export default userSlice.reducer