import React, { useEffect, useRef, useState } from "react";
import { headerStyles } from "../../Styles/HeaderStyle";
import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../Shared/Buttons";
import Popover from '@mui/material/Popover';
import MenuItem from "@mui/material/MenuItem";
import { toast } from 'react-toastify';
import ListItemText from "@mui/material/ListItemText";
import Api from "../../helper/api";
import { useDispatch, useSelector } from "react-redux"
import { setLoggedIn, setUser } from "../../redux/userSlice";
import { isAllowed } from "../../helper/helper";
import { setPcDetails } from "../../redux/pcDetails";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const getDeviceFingerprint = async () => {
  let fingerprintExist = localStorage.getItem("fingerprintId");

  if (fingerprintExist) {
    return fingerprintExist
  } else {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    localStorage.setItem("fingerprintId", result.visitorId)
    return result.visitorId; // Unique device ID
  }
};

const links = {
  "Doctor": ["add-doctor", "register-doctor", "edit-doctor", "doctor"],
  "Users": ["add-user", "edit-user", "register-user", "AccessRight", "view-user"],
  "SampleCollector": ["register-collector", "add-collector", "edit-collector"],
  "Test": ["register-new-test", "add-test", "edit-test"],
  "ReportGroup": ["add-report-group", "register-report-group", "edit-report-format"],
  "ReportFormat": ["register-report-format", "add-report-format", "edit-reports-format", "view-reports-format"],
  "PriceList": ["add-price-list", "register-price-list", "edit-price-list", "view-price-list"],
  "AccountList": ["get-account-list", "add-new-account", "edit-account-list"],
  "SampleFrom": ["get-sample-list", "register-sample-list", "edit-sample-list"],
  "Patients": ["list-patient", "add-patience", "edit-patience", "appointment-view", "patient-report"],
  "PatientCards": ["add-patience-cards", "patience-cards", "edit-patient-card"],
  "Samplecollectors": ["assign-collector"],
  "Visits": ["add-visit", "visitor-book", "edit-visiting-patience"],
  "Payments": ["payments"],
  "Doctor-wise": ["doctor-wise-payments"],
  "Macro": ["macro"],
  "Signature": ["signatures"],
  "PCs": ["pcs"]
}

const tabs = {
  "Accounts": [
    ...links["Doctor"], ...links["Users"], ...links["SampleCollector"], ...links["Signature"], ...links["PCs"]
  ],
  "MasterData": [
    ...links["Test"], ...links["Macro"], ...links["ReportGroup"], ...links["ReportFormat"], ...links["PriceList"], ...links["AccountList"], ...links["SampleFrom"]
  ]
}

const Header = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(localStorage.getItem("logintoken") || "");
  const isActive = true;
  const api = new Api();
  const [value, setValue] = React.useState("1");
  const [anchorEl, setAnchorEl] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("")
  const [showNavbar, setShowNavbar] = useState(false);
  const [hoverThrough, setHoverThrough] = useState(null)
  const { data, isLoggedIn } = useSelector(state => state.user)
  const { pcDetails } = useSelector(state => state.pcDetails)
  const callbackUrl = new URLSearchParams(location.search).get('callbackUrl');

  const handleMouseEnter = (hoverThrough) => {
    setShowNavbar(true); // Show navbar
    setHoverThrough(hoverThrough)
  };

  const handleMouseLeave = () => {
    setShowNavbar(false); // Hide navbar
    setHoverThrough(null)
  };

  const pathname = location.pathname.split("/")[1];
  const path = location.pathname

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (token) {
      findUser()
      findPcDetails();
    }
  }, [token]);

  const findUser = async () => {
    try {
      const { data, status } = await api.whoAmI();

      if (status === 200) {
        dispatch(setUser(data.data))
        dispatch(setLoggedIn(true))
      }
    } catch (err) {
      dispatch(setLoggedIn(false))
      dispatch(setUser({
        email: null,
        role: null,
        permissions: [],
      }))
    }
  }

  const findPcDetails = async () => {
    try {
      const fingerprintId = await getDeviceFingerprint();

      const { data, status } = await api.getPcDetails({
        fingerprintId
      });

      if (status === 200) {
        dispatch(setPcDetails({
          fingerprintId: data.data.fingerprintId,
          branchId: data.data?.branchId || "",
          pcName: data.data?.pcName || ""
        }))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const headclasses = headerStyles();

  const user = localStorage.getItem("user");
  const user_role = localStorage.getItem("role");


  useEffect(() => {
    setRole(user_role)
  }, [user_role])

  let username = user?.split("@")[0];
  let capitalizedUsername = username?.charAt(0).toUpperCase() + username?.slice(1);

  const logout = () => {
    handleClose();
    localStorage.removeItem("logintoken");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    toast.success("Logged out Successfully");
    setToken("")
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1500)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (tabs["Accounts"]?.includes(pathname)) {
      setValue("1")
    } else if (tabs["MasterData"]?.includes(pathname)) {
      setValue("2")
    } else {
      setValue("3")
    }
  }, [pathname])

  useEffect(() => {
    if (isLoggedIn) {
      if (pathname === "") {
        if (callbackUrl) {
          return navigate(callbackUrl)
        }

        navigate("/list-patient")
      }
    } else {
      if (pathname === "") {
        return () => { }
      }

      if (callbackUrl) {
        return navigate(`/?callbackUrl=${callbackUrl}`)
      }

      // if user is on another route then give the callback url
      if (path !== "/") {
        return navigate(`/?callbackUrl=${location.pathname}`)
      }

      navigate("/")
    }
  }, [pathname, isLoggedIn])

  return (
    token && <div className={headclasses.headerMain}>
      {
        (data.role === "user") && !pcDetails?.branchId &&
        <OverLayWarning fingerprintId={pcDetails?.fingerprintId} />
      }
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            width: "16%",
            backgroundColor: "#B82C3A",
            height: "52px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#F4FFF3",
            fontSize: "18px",
            fontStyle: "bold",
            paddingTop: '20px',
            textTransform: 'uppercase',
          }}
        >
          Nagalikar
        </div>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{
              backgroundColor: "#B82C3A",
              height: "52px",
              paddingTop: '12px',
              textTransform: 'none',
            }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                style={{
                  height: "54px",
                  border: "none",
                  color: "#F4FFF3",
                  fontFamily: 'Poppins',
                }}
                className={headclasses.tabs}
              >
                < Tab
                  className={headclasses.tablist}
                  label="Accounts"
                  value="1"
                  // onClick={() => navigate('/register-doctor')}
                  onMouseEnter={(e) => handleMouseEnter("1")}
                  onMouseLeave={handleMouseLeave}
                />

                <Tab
                  className={headclasses.tablist}
                  label="Master data"
                  value="2"
                  // onClick={() => navigate('/add-test')}
                  onMouseEnter={(e) => handleMouseEnter("2")}
                  onMouseLeave={handleMouseLeave}
                />
                <Tab
                  className={headclasses.tablist}
                  label="Patients"
                  value="3"
                  // onClick={() => navigate('/list-patient')}
                  onMouseEnter={(e) => handleMouseEnter("3")}
                  onMouseLeave={handleMouseLeave}
                />
              </TabList>
            </Box>
            {
              <TabPanel value="1">
                <div
                  onMouseEnter={(e) => handleMouseEnter("1")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: 10, background: "#fff", zIndex: 1000, position: "absolute", top: 52, borderRadius: 5, boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)", display: showNavbar && hoverThrough == "1" ? "block" : 'none' }}>

                  {
                    isAllowed(data.permissions, "ACCOUNT_DOCTOR", data.role) &&

                    <Link to="/register-doctor" className={links["Doctor"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        active={isActive}
                        className={headclasses.customButton}
                      >
                        Doctor
                      </Button>
                    </Link>
                  }
                  {
                    isAllowed(data.permissions, "ACCOUNT_USER", data.role) &&

                    <Link to="/register-user" className={links["Users"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button className={headclasses.customButton}>Users</Button>
                    </Link>
                  }
                  {
                    isAllowed(data.permissions, "ACCOUNT_SAMPLE_COLLECTOR", data.role) &&
                    <Link to="/register-collector" className={links["SampleCollector"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button className={headclasses.customButton}>
                        Sample collector
                      </Button>
                    </Link>
                  }
                  {
                    isAllowed(data.permissions, "ACCOUNT_SIGNATURE", data.role) &&
                    <Link to="/signatures" className={links["Signature"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button className={headclasses.customButton}>
                        Signature
                      </Button>
                    </Link>
                  }

                  {
                    isAllowed(data.permissions, "ACCOUNT_PCS", data.role) &&
                    <Link to="/pcs" className={links["PCs"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button className={headclasses.customButton}>
                        PCs
                      </Button>
                    </Link>
                  }
                </div>
              </TabPanel>
            }
            {
              <TabPanel value="2">
                <div
                  onMouseEnter={(e) => handleMouseEnter("2")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: 10, background: "#fff", zIndex: 1000, position: "absolute", top: 52, borderRadius: 5, boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)", display: showNavbar && hoverThrough == "2" ? "block" : 'none' }}>
                  {
                    isAllowed(data.permissions, "REPORT_GROUP", data.role) &&

                    <Link to="/add-report-group" className={links["ReportGroup"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        // to="/my-page"
                        className={headclasses.customButton}
                      >
                        Report Group
                      </Button>
                    </Link>
                  }
                  {
                    isAllowed(data.permissions, "TEST", data.role) &&
                    <Link to="/add-test" active={isActive} className={links["Test"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button className={headclasses.customButton}>
                        Test
                      </Button>
                    </Link>
                  }
                  {
                    isAllowed(data.permissions, "REPORT_FORMAT", data.role) &&
                    <Link to="/add-report-format" className={links["ReportFormat"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        // to="/my-page"
                        className={headclasses.customButton}
                      >
                        Report Format
                      </Button>
                    </Link>
                  }
                  {
                    isAllowed(data.permissions, "PRICE_LIST", data.role) &&
                    <Link to="/add-price-list" className={links["PriceList"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        // to="/my-page"
                        className={headclasses.customButton}
                      >
                        Price list
                      </Button>
                    </Link>
                  }

                  {
                    isAllowed(data.permissions, "ACCOUNT", data.role) &&
                    <Link to="/get-account-list" className={links["AccountList"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        // to="/my-page"
                        className={headclasses.customButton}
                      >
                        Account List
                      </Button>
                    </Link>
                  }

                  {
                    <Link to="/get-sample-list" className={links["SampleFrom"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        // to="/my-page"
                        className={headclasses.customButton}
                      >
                        Sample From
                      </Button>
                    </Link>
                  }

                  {
                    isAllowed(data.permissions, "MASTER_MACRO", data.role) &&
                    <Link to="/macro" className={links["Macro"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        // to="/my-page"
                        className={headclasses.customButton}
                      >
                        Macro
                      </Button>
                    </Link>
                  }
                </div>
              </TabPanel>
            }

            {
              value == "3" && <TabPanel value="3">
                <div
                  onMouseEnter={(e) => handleMouseEnter("3")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: 10, background: "#fff", zIndex: 1000, position: "absolute", top: 52, borderRadius: 5, boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)", display: showNavbar && hoverThrough == "3" ? "block" : 'none' }}>
                  {
                    isAllowed(data.permissions, "PATIENTS", data.role) &&
                    <Link to="/list-patient" className={links["Patients"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        to="/list-patient"
                        className={headclasses.customButton}
                      >
                        Patients
                      </Button>
                    </Link>
                  }
                  {
                    isAllowed(data.permissions, "PATIENT_CARDS", data.role) &&
                    <Link to="/patience-cards" className={links["PatientCards"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        to="/patience-cards"
                        className={headclasses.customButton}
                      >
                        Patient cards
                      </Button>
                    </Link>
                  }

                  <Link to="/assign-collector" className={links["Samplecollectors"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      to="/my-page"
                      className={headclasses.customButton}
                    >
                      Sample collectors
                    </Button>
                  </Link>


                  {
                    isAllowed(data.permissions, "PATIENT_VISITS", data.role) &&
                    <Link to="/visitor-book" className={links["Visits"]?.includes(pathname) ? headclasses.linkActive : ""}>
                      <Button
                        // component={Link}
                        to="/my-page"
                        className={headclasses.customButton}
                      >
                        Visits
                      </Button>
                    </Link>
                  }

                  <Link to="/payments" className={links["Payments"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      className={headclasses.customButton}
                    >
                      Payments
                    </Button>
                  </Link>

                  <Link to="/doctor-wise-payments" className={links["Doctor-wise"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      className={headclasses.customButton}
                    >
                      Doctor Wise Payments
                    </Button>
                  </Link>

                </div>
              </TabPanel>
            }

          </TabContext>
        </Box>
        <div
          style={{
            width: "25%",
            backgroundColor: "#B82C3A",
            height: "52px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#F4FFF3",
              textAlign: "right",
              marginRight: "6px",
              cursor: "pointer",
              position: "relative",
              textTransform: 'uppercase',
            }}
            onClick={handleClick}
          >
            <span>{capitalizedUsername}</span>
            <span>{role}</span>
          </div>
          <Popover
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                backgroundColor: '#273142',
                color: '#FFFFFF',
                borderRadius: '10px',
                padding: "10px",
                width: '100px',
              },
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={logout}>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Popover>
          <p
            style={{
              color: "#FFFFFF",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "20px",
              fontWeight: "bold",
              borderRadius: "6px",
              background: "rgba(255, 255, 255, 0.08)"
            }}
          >
            {capitalizedUsername && capitalizedUsername?.charAt(0)?.toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
};

const OverLayWarning = ({ fingerprintId }) => {
  const navigate = useNavigate()

  const baseStyles = "px-4 py-2 rounded-lg font-medium transition-all";
  const variants = {
    default: "bg-red-600 text-white hover:bg-red-700",
    outline: "border border-gray-400 text-gray-700 hover:bg-gray-100",
  };

  const navigateToLogin = () => {
    localStorage.clear();
    window.location = "/"
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 absolute z-50">
      <div
        className="bg-white p-6 rounded-2xl shadow-xl text-center space-y-4"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <h2 className="text-xl font-semibold text-red-600">PC is not assigned any branch</h2>
        <h4>FingerprintId: {fingerprintId}</h4>
        <div className="flex justify-center gap-4">
          <button className={`${baseStyles} ${variants["default"]}`} onClick={() => window.location.reload()} variant="outline">Refresh</button>
          <button className={`${baseStyles} ${variants["outline"]}`} onClick={navigateToLogin} variant="default">Login</button>
        </div>
      </div>
    </div>
  )
}

export default Header;
