import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
// import { formatedDate, formatedDateInDigit } from "../../helper/helper";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
// import AppointmentPop from "../../Components/Shared/AppointmentPopover";
// import EditSample from "../../Components/Shared/EditSample";
import Patient from "./../patience/Patient";
import Button from "@mui/material/Button";
import {
  convertTo12HourFormat,
  formatTime,
  formatedDateInDigit,
  isAllowed,
} from "../../helper/helper";
import AddnewBtn from "../patience/AddnewBtn";
import EditCollection from "../patience/EditCollection";
import UpdateCollection from "../patience/UpdateCollection";
import DeleteCollection from "../patience/DeleteCollection";
import PaymentCollection from "../patience/paymentCollection";
import EditReceipt from "../patience/EditReceipt";
import Investigation from "../patience/Investigation";
import UploadImg from "../patience/UploadImg";
import ShowReport from "../patience/ShowReport";
import { toast } from "react-toastify";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";

const AppointMent = () => {
  const tableclasses = tableStyles();
  const classes = formStyles();
  const navigate = useNavigate();
  const [popupForm, setPopupForm] = useState(false);
  const [popup, setPopup] = useState(false);
  const [editCollection, setEditCollection] = useState(false);
  const [updateCollection, setUpdateCollection] = useState(false);
  const [deleteCollection, setDeleteCollection] = useState(false);
  const [paymentCollection, setPaymentCollection] = useState(false);
  const [receipt, setReceipt] = useState(false);
  const [upload, setUpload] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const { data } = useSelector(state => state.user);

  const [showReport, setShowReport] = useState({
    show: false,
    src: ""
  })
  const [paymentToEdit, setPaymentToEdit] = useState(null);
  const [slip, setSlip] = useState([])
  const [investigationToDelete, setInvestigationToDelete] = useState(null);

  const [collectionToEdit, setCollectionToEdit] = useState(null);
  const [rows, setRows] = useState();
  const [reFetch, setReFetch] = useState(false);
  const [assign, setAssign] = useState(false);
  const params = useParams();
  const [payment, setPayment] = useState({
    totalAmount: 0,
    netAmount: 0,
    totalDiscount: 0,
    totalPaidAmount: 0,
    totalDueAmount: 0
  });

  const TOKEN = localStorage.getItem("logintoken");

  const fetchData = async () => {
    const { data, status } = await axios.get(
      `${API}/get-patience/${params.id}`,
      {
        headers: { authtoken: `${TOKEN}` },
      }
    );
    setRows(data);

    if (data.refSlipImg) {
      setSlip(data.refSlipImg)
    }

    const payment = data?.payment;
    let totalAmount = 0;
    let totalDiscount = 0;
    let netAmount = 0;
    let totalPaidAmount = 0;

    for (let i = 0; i < payment.bills.length; i++) {
      totalAmount += payment.bills[i].totalAmount;
      totalDiscount += payment.bills[i].discount;
      netAmount += payment.bills[i].netAmount;
    }

    for (let i = 0; i < payment.deposits.length; i++) {
      totalPaidAmount += payment.deposits[i].paidAmount;
    }

    setPayment({
      totalAmount,
      totalDiscount,
      netAmount,
      totalPaidAmount,
      totalDueAmount: netAmount - totalPaidAmount,
      payment
    });
  };


  useEffect(() => {
    fetchData();
  }, [reFetch]);

  useEffect(() => {
    if (!TOKEN) {
      navigate("/");
    }
  }, [TOKEN]);

  const handleBarCode = (sample) => {
    if (sample.sampleNo) {
      navigate(`/barcode-collection/${params.id}/${sample.sampleNo}`);
    } else {
      toast.error("Sample No not available")
    }
  }
  return (
    <div className={tableclasses.root} style={{ margin: "25px" }}>
      {editCollection && (
        <EditCollection
          setEditCollection={setEditCollection}
          collectionToEdit={collectionToEdit}
          setReFetch={setReFetch}
          id={params.id}
          reFetch={reFetch}
          setCollectionToEdit={setCollectionToEdit}
          rows={rows}
        />
      )}
      {updateCollection && (
        <UpdateCollection
          setUpdateCollection={setUpdateCollection}
          collectionToEdit={collectionToEdit}
          setReFetch={setReFetch}
          reFetch={reFetch}
          setCollectionToEdit={setCollectionToEdit}
          rows={rows}
        />
      )}
      {deleteCollection && (
        <DeleteCollection
          investigationToDelete={investigationToDelete}
          setReFetch={setReFetch}
          reFetch={reFetch}
          setDeleteCollection={setDeleteCollection}
        />
      )}
      {receipt && (
        <EditReceipt
          setReceipt={setReceipt}
          paymentToEdit={paymentToEdit}
          setReFetch={setReFetch}
          reFetch={reFetch}
          rows={rows}
          id={params.id}
        />
      )}
      {upload && <UploadImg
        setReFetch={setReFetch}
        reFetch={reFetch}
        setUpload={setUpload}
        id={params.id}
        setShowReport={setShowReport} />}
      {showReport.show ? <ShowReport handleClose={() => setShowReport({ show: false, src: "" })} showReport={showReport} /> : ""}
      <div className={tableclasses.appointmentbody}>
        <div className={tableclasses.headertwo}>
          <div className={classes.formname}>
            <div className={classes.formh2}>Brooklyn Simmons</div>
            <div className={classes.viewHead}>
              <div
                className={`${classes.formspecification}`}
                style={{ marginLeft: "50px", marginRight: "50px" }}
              >
                Patient ID: {params.id}
              </div>
              <div className={classes.formspecification}>Sample no: {rows?.sampleCollection[0]?.sampleNo}</div>
              <div className={classes.formspecification}>
                Status:{" "}
                <span className={classes.status}>
                  {rows?.sampleCollection[0]?.sampleStatus}
                </span>
              </div>
              {rows?.urgentRequired && (
                <div
                  className={classes.formspecification}
                  style={{ color: "#B82C3A" }}
                >
                  Urgent Requirement
                </div>
              )}
            </div>
            <Buttons
              className={classes.backToButton}
              style={{ width: "120px" }}
              onClick={() => navigate("/list-patient")}
            >
              Back to Table
            </Buttons>
          </div>
        </div>
        <div className={classes.biodata}>
          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Lab</p>
              <p>Patient name</p>
              <p>Age/Sex</p>
              <p>Mobile number</p>
              <p>Email address</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
              <p>{rows?.labPrefix?.prefix ? rows?.labPrefix?.prefix : "NA"}</p>
              <p>
                {rows?.firstName} {rows?.lastName}
              </p>
              <p>
                {rows?.age}yrs / {rows?.gender}
              </p>
              <p>{rows?.phone ? rows?.phone : "NA"}</p>
              <p>{rows?.email ? rows?.email : "NA"}</p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Referred by</p>
              <p>Sample from</p>
              <p>Created date</p>
              <p>Address</p>
              <p>Reference ID</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
              <p>{rows?.refferedBy?.name ? rows?.refferedBy?.name : "Self"}</p>
              <p>{rows?.sampleFrom ? rows?.sampleFrom : "NA"}</p>
              <p>{formatedDateInDigit(rows?.createdAt)}</p>
              <p>{rows?.address ? rows?.address : "NA"}</p>
              <p>{rows?.refID ? rows?.refID : "NA"}</p>
            </div>
          </div>

          <div className={classes.imageside}>
            <div className={classes.patientImage}>
              Reference slip image :{" "}
              {rows?.assignToUser && `Assigned to ${rows?.assignToUser?.name}`}
            </div>
            <div
              className={classes.refslip}
              style={{
                minWidth: "190px",
                height: "98px",
                marginTop: "9px",
                padding: "10px 20px"
              }}
            >
              {slip.length > 0 ? (
                <div style={{ display: "flex", gridGap: "15px" }}>
                  {
                    slip.map((slip, i) => {
                      return (
                        slip.src &&
                        <div style={{ position: 'relative', }}>
                          <img
                            src={slip.src}
                            style={{
                              width: "70px",
                              height: "80px",
                              objectFit: "cover",
                            }}
                          />
                          <div className={classes.vector} onClick={() => setShowReport({ show: true, src: slip.src })}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M0.00200907 14.9975L0 24H9.00347L6.09413 21.091L12.0917 15.0937L8.90707 11.909L2.90951 17.9063L0.00200907 14.9975ZM14.9996 0.000457341L17.906 2.90654L11.9056 8.90671L15.0902 12.091L21.0907 6.0908L24 9.00026V0L14.9996 0.000457341Z"
                                fill="#666666"
                              />
                              <path
                                d="M9.00254 0.00200877L1.90735e-06 -4.80825e-07L1.04868e-06 9.00347L2.909 6.09413L8.90631 12.0917L12.091 8.90707L6.09366 2.90951L9.00254 0.00200877ZM23.9995 14.9996L21.0935 17.906L15.0933 11.9056L11.909 15.0902L17.9092 21.0907L14.9997 24L24 24L23.9995 14.9996Z"
                                fill="#666666"
                              />
                            </svg>
                          </div>
                        </div>
                      )
                    })
                  }
                  <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                    <Buttons onClick={() => setUpload(true)} className={classes.filterButton}>+ </Buttons>
                  </div>
                </div>
              ) : (
                <Buttons
                  component="label"
                  onClick={() => setUpload(true)}
                  className={`${classes.refslip2} ${classes.filterButton2}`}
                >
                  Upload Image
                  {/* <input
                  type="file"
                  name="uploadImg"
                  style={{ display: "none" }}
                  multiple
                  id="uploadImg"
                /> */}
                </Buttons>
              )}
            </div>
          </div>
        </div>
        <div
          className={tableclasses.accessHeader}
          style={{ flexDirection: "column" }}
        >
          <div className={tableclasses.accessHeader}>
            <div className={classes.formh2}>Investigations</div>
            {
              isAllowed(data.permissions, "INVESTIGATION_ADD", data.role) &&
              <Button
                variant="contained"
                className={tableclasses.findButton}
                style={{ width: "94px" }}
                onClick={() => setPopupForm(true)}
              >
                Add New
              </Button>
            }
            {popupForm ? <Investigation id={params.id} reFetch={reFetch} setReFetch={setReFetch} setPopupForm={setPopupForm} /> : null}
          </div>
          <div
            className={`${tableclasses.accessHeader} ${tableclasses.firstBox}`}
          >
            <div className={`${classes.headerAp} ${classes.appointhead}`}>
              <p className={classes.firstp}>Item</p>
              <p className={classes.secp}>Short Name</p>
              <p className={classes.secp}>Rate/Price</p>
              <p className={classes.fourp}>Category</p>
              <p className={classes.fourp}>Collection</p>
              <p className={classes.secp}>Action</p>
            </div>
            <hr
              style={{
                border: "1px solid #B5B5C3",
                width: "100%",
                marginTop: "0",
                marginBottom: "9px",
              }}
            />
            {rows?.investigation?.map((investigation) => (
              <div
                key={investigation?._id}
                className={`${classes.headerAp} ${classes.textmenu}`}
              >
                <p className={classes.firstp}>
                  {investigation?.priceListName
                    ? investigation?.priceListName
                    : "NA"}
                </p>
                <p className={classes.secp}>
                  {investigation?.shortName ? investigation?.shortName : "NA"}
                </p>
                <p className={classes.secp}>₹{investigation?.rate}</p>
                <p className={classes.fourp}>{investigation?.category?.name}</p>
                <p className={classes.fourp}>
                  {investigation?.sampleCollection}
                </p>
                <p className={classes.secp}>
                  <PopoverMenu
                    type="investigation"
                    handleDelete={() => {
                      setDeleteCollection(true);
                      setInvestigationToDelete(investigation._id);
                    }}
                    permission={data}
                  />
                </p>
              </div>
            ))}
          </div>
        </div>

        <div
          className={tableclasses.accessHeader2}
          style={{ flexDirection: "column" }}
        >
          <div className={tableclasses.accessHeader}>
            <div className={classes.formh2}>Sample collection</div>
            <Button
              variant="contained"
              onClick={() => setPopup(true)}
              className={tableclasses.findButton}
              style={{ width: "94px" }}
            >
              Add New
            </Button>
            {popup ? (
              <AddnewBtn
                setPopup={setPopup}
                id={params.id}
                reFetch={reFetch}
                setReFetch={setReFetch}
              />
            ) : (
              ""
            )}
          </div>
          <div
            className={`${tableclasses.accessHeader} ${tableclasses.firstBox}`}
          >
            <div className={`${classes.headerAp} ${classes.appointhead}`}>
              <p className={classes.thirdp}>Sample</p>
              <p className={classes.thirdp}>Date & Time</p>
              <p className={classes.fourp}>Status</p>
              <p className={classes.fourp}>Bar code</p>
              <p className={classes.secp}>Options</p>
            </div>
            <hr
              style={{
                border: "1px solid #B5B5C3",
                width: "100%",
                marginTop: "0",
                marginBottom: "9px",
              }}
            />
            {rows?.sampleCollection?.map((sample) => (
              <div
                key={sample?._id}
                className={`${classes.headerAp} ${classes.textmenu}`}
              >
                <p className={classes.thirdp}>{sample?.sampleType}</p>
                <p className={classes.thirdp}>
                  {" "}
                  {sample?.date
                    ? `${formatedDateInDigit(sample?.date)}, ${formatTime(
                      sample?.date
                    )}`
                    : ""}
                </p>
                <p className={classes.fourp}>{sample?.sampleStatus}</p>
                <p className={classes.fourp}>
                  {
                    sample?.sampleNo ? <Barcode displayValue={false} width={1} height={30} fontSize={14} value={sample?.sampleNo} /> : ""
                  }
                </p>
                <p className={classes.secp}>
                  <PopoverMenu
                    type="sample"
                    handleEdit={() => {
                      setEditCollection(true);
                      setCollectionToEdit(sample._id);
                    }}
                    handleUpdate={() => {
                      setUpdateCollection(true);
                      setCollectionToEdit(sample._id);
                    }}
                    handleBarcode={() => handleBarCode(sample)}
                  />
                </p>
              </div>
            ))}
          </div>
        </div>
        <div
          className={tableclasses.accessHeader2}
          style={{ flexDirection: "column" }}
        >
          <div className={tableclasses.accessHeader}>
            <div className={classes.formh2}>Payment & reciept details</div>
            {
              isAllowed(data.permissions, "RECEIPT_ADD", data.role) &&
              <Button
                variant="contained"
                className={tableclasses.findButton}
                onClick={() => setPaymentCollection(true)}
                style={{ width: "94px" }}
              >
                Add New
              </Button>
            }
            {paymentCollection && (
              <PaymentCollection
                payment={payment}
                setPayment={setPaymentCollection}
                setReFetch={setReFetch}
                reFetch={reFetch}
                id={params.id}
              />
            )}
          </div>
          <div
            className={`${classes.headerAp} ${classes.textmenu}`}
            style={{ fontWeight: "500", marginLeft: "-4%" }}
          >
            <p>
              Total amount <span className="px-3"></span>:{" "}
              <span className="px-3"></span>₹{payment.totalAmount}
            </p>
            <p>
              Discount amount <span className="px-3"></span>:{" "}
              <span className="px-3"></span>₹{payment.totalDiscount}
            </p>
            <p>
              Net amount <span className="px-3"></span>:{" "}
              <span className="px-3"></span>₹{payment.netAmount}
            </p>
            <p>
              Total Due amount <span className="px-3"></span>:{" "}
              <span className="px-3"></span>₹{payment.totalDueAmount}
            </p>
          </div>
          <div
            className={`${tableclasses.accessHeader} ${tableclasses.firstBox}`}
          >
            <div className={`${classes.headerAp} ${classes.appointhead}`}>
              <p style={{ width: '16.5%' }}>Reciept no</p>
              <p style={{ width: '16.5%' }}>Paid Amount</p>
              <p style={{ width: '16.5%' }}>Mode</p>
              <p style={{ width: '16.5%' }}>Operator</p>
              <p style={{ width: '12.5%', display: "flex", justifyContent: "flex-end" }}>Options</p>
            </div>
            <hr
              style={{
                border: "1px solid #B5B5C3",
                width: "100%",
                marginTop: "0",
                marginBottom: "9px",
              }}
            />
            {rows?.payment.deposits?.map((payment) => (
              <div
                key={payment?._id}
                className={`${classes.headerAp} ${classes.textmenu}`}
              >
                <p style={{ width: '16.5%' }}>{payment?.paymentNo ? payment?.paymentNo : "NA"}</p>
                <p style={{ width: '16.5%' }}>{`₹${payment?.paidAmount}`}</p>
                <p style={{ width: '16.5%' }}>{payment?.mode ? payment?.mode : "NA"}</p>
                <p style={{ width: '16.5%' }}>ABHI</p>
                <p style={{ width: '12.5%', display: "flex", justifyContent: "flex-end" }}>
                  <PopoverMenu
                    type="payment"
                    permission={data}
                    handlePayment={() => {
                      setReceipt(true);
                      setPaymentToEdit(payment._id);
                    }}
                  />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const PopoverMenu = ({
  data,
  permission,
  handleEdit,
  handleDelete,
  handleUpdate,
  handleBarcode,
  handlePayment,
  type,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [role, setRole] = useState("");
  const user_role = localStorage.getItem("role");

  const path = location.pathname.split("/")[1];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setRole(user_role)
  }, [user_role])

  const handleClose = () => {
    setAnchorEl(false);
  };

  const deleteInvestigation = () => {
    if (role === "admin") {
      handleDelete()
    } else {
      toast.error("Permission not granted!")
    }
  }

  return (
    <div>
      <IconButton
        onClick={handleClick}
        style={{
          height: "32px",
          width: "32px",
          textAlign: "center",
          borderRadius: "6px",
          backgroundColor: "#FAFAFA",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            fontSize: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "13px",
            "&.MuiIconButton-label": {
              paddingBottom: "13px",
              marginTop: "-12px",
            },
          }}
        >
          ...
        </span>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#273142",
            color: "#FFFFFF",
            borderRadius: "16px",
            minWidth: "114px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {type === "investigation" && isAllowed(permission.permissions, "INVESTIGATION_DELETE", permission.role) && (
          <MenuItem
            onClick={() => {
              deleteInvestigation();
              handleClose();
            }}
          >
            <ListItemText
              primary="Delete"
              style={{
                borderRadius: "43px",
                border: "1px solid rgba(255, 255, 255, 0.2)",
                textAlign: "center",
                padding: "8px 10px",
              }}
            />
          </MenuItem>
        )}
        {type === "sample" && (
          <>
            <MenuItem
              onClick={() => {
                handleEdit(data);
                handleClose();
              }}
            >
              <ListItemText
                primary="Edit"
                style={{
                  borderRadius: "43px",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  textAlign: "center",
                  padding: "8px 10px",
                }}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleUpdate(data);
                handleClose();
              }}
            >
              <ListItemText
                primary="Update Status"
                style={{
                  borderRadius: "43px",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  textAlign: "center",
                  padding: "8px 10px",
                }}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleBarcode(data);
                handleClose();
              }}
            >
              <ListItemText
                primary="Print barcode"
                style={{
                  borderRadius: "43px",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  textAlign: "center",
                  padding: "8px 10px",
                }}
              />
            </MenuItem>
          </>
        )}

        {type === "payment" && (
          <>
            {
              isAllowed(permission.permissions, "RECEIPT_EDIT", permission.role) &&
              <MenuItem
                onClick={() => {
                  handlePayment(data);
                  handleClose();
                }}
              >
                <ListItemText
                  primary="Edit"
                  style={{
                    borderRadius: "43px",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    textAlign: "center",
                    padding: "8px 10px",
                  }}
                />
              </MenuItem>
            }
            {
              isAllowed(permission.permissions, "RECEIPT_PRINT", permission.role) &&
              <MenuItem onClick={() => handleEdit(data)}>
                <ListItemText
                  primary="Print"
                  style={{
                    borderRadius: "43px",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    textAlign: "center",
                    padding: "8px 10px",
                  }}
                />
              </MenuItem>
            }
          </>
        )}
      </Popover>
    </div>
  );
};

export default AppointMent;
