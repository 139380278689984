import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import { DoctorSvg, DropdownSvg, FilterSvg, ResetFilterSvg } from "../../Components/Shared/UserSvg";
import PopoverMenu from "../../Components/Shared/Popover";
import { convertTo12HourFormat, formatTime, formatedDate, isAllowed } from "../../helper/helper";
import { useReactToPrint } from "react-to-print";
import { FiSearch } from "react-icons/fi";
import FilterCategory from "../MasterData/FilterCategory";
import { useSelector } from "react-redux";

const Visitor = () => {
  const tableclasses = tableStyles();
  const navigate = useNavigate();

  const [newData, setNewData] = useState(false);
  const TOKEN = localStorage.getItem("logintoken");
  const [search, setSearch] = useState("");

  const [rows, setRows] = useState(null);
  const reportContent = useRef();
  const [filter, setFilter] = useState({
    date: null,
    status: null,
  });
  const [filterData, setFilterData] = useState({
    date: "",
    status: "",
  })
  const { data } = useSelector(state => state.user);

  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
    // onAfterPrint:alert("Pdf Downloaded")
  })

  const handleReset = () => {
    setFilterData({
      date: "",
      status: ""
    })
  }


  const fetchData = async () => {
    const { date, status } = filterData;
    const data = await axios.get(`${API}/get-booking-list?date=${JSON.stringify(date)}&search=${search}&status=${status}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setRows(data?.data?.bookingList);
  };

  useEffect(() => {
    fetchData();
  }, [newData, filterData.date, search, filterData.status]);

  useEffect(() => {
    if (!TOKEN) {
      navigate("/");
    }
  }, [TOKEN]);

  const handleEdit = (id) => {
    navigate(`/edit-visiting-patience/${id}`);
  };

  const handleDelete = async (id) => {
    const data = await axios.delete(`${API}/delete-booking-list/${id}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    if (data?.data?.message === "Booking removed successfully") {
      setNewData(true);
      toast.success("Doctor removed successfully");
      setNewData(false);
    }
  };

  const header = [

    "ID", "Scheduled Date & Time", "Name & Id",
    " Phone No",
    "Investigation", "Completed Date & Time", "Lab No", "Visit By", "Amount",
    "Status",
    "Options",
  ];
  const status = ["Pending", "Collected"]
  return (
    <Box className={tableclasses.root}>
      <FilterCategory status={status} setFilter={setFilter} filter={filter}
        filterData={filterData} setFilterData={setFilterData} />
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.h2}>Visit List</div>
            <div className={tableclasses.specification}>
              {rows?.length} available Visits
            </div>
          </div>
          <div>
            {
              isAllowed(data.permissions, "PATIENT_VISIT_ADD", data.role) &&
              <Buttons
                className={tableclasses.addButton}
                onClick={() => navigate("/add-visit")}
              >
                <DoctorSvg /> &nbsp; Create New Visit
              </Buttons>
            }
          </div>
        </div>
        <div className={tableclasses.filterSearch}>
          <div>
            <Buttons className={tableclasses.filterButton1}>
              <FilterSvg />
            </Buttons>
            <Buttons className={tableclasses.filterButton2}>Filter By</Buttons>
            <Buttons onClick={(e) => setFilter((prev) => {
              return { ...prev, date: e.currentTarget }
            })} className={tableclasses.filterButton2}>Date
              <DropdownSvg /> </Buttons>
            {/* <Buttons onClick={filterByCategory} className={tableclasses.filterButton2}>Location</Buttons> */}
            <Buttons onClick={(e) => setFilter((prev) => {
              return { ...prev, status: e.currentTarget }
            })} className={tableclasses.filterButton2}>
              Sample Status
              <DropdownSvg />
            </Buttons>
            <Buttons
              onClick={handleReset}
              className={tableclasses.filterButton3}>
              <ResetFilterSvg />
              <span style={{ color: "#FF8743", marginLeft: 6, text: "center" }}>
                Reset FIlter
              </span>{" "}
            </Buttons>
            <Buttons className={tableclasses.printButton} onClick={generatePDF}>Print</Buttons>
          </div>

          <div className={tableclasses.searchContainer}>
            <FiSearch className={tableclasses.searchIcon} />
            <TextField
              className={tableclasses.searchField}
              placeholder="Search"
              variant="standard"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <Table className={tableclasses.table} ref={reportContent}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              {header.map((header) => {
                return (
                  <TableCell className={tableclasses.customTableCell}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, ind) => (
              <TableRow key={row.email}>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                >
                  <div className={tableclasses.profile}>
                    <div>{ind + 1}</div>
                    <div className={tableclasses.name}>
                      <div></div>
                      <div className={tableclasses.specification}></div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div className={tableclasses.name}>
                    <div>{formatedDate(row?.date)}</div>
                    <div className={tableclasses.specification}>
                      {convertTo12HourFormat(row?.time)}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div className={tableclasses.name}>
                    <div>{row?.patienceName}</div>
                    <div className={tableclasses.specification}>
                      {row?.patId}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.phone}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>-</div>
                  {/* <div className={tableclasses.specification}>{row.area}</div> */}
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div className={tableclasses.name}>
                    <div>{row?.createdAt ? formatedDate(row?.createdAt) : "NA"}</div>
                    <div className={tableclasses.specification}>
                      {row?.createdAt && formatTime(row?.createdAt)}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>-</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.visitby}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.amount}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <Buttons className={row.status.toUpperCase() === "COLLECTED" ? tableclasses.customActive : tableclasses.customInActive}>
                    <div style={{ color: row.status.toUpperCase() === "COLLECTED" ? 'green' : 'red' }}>
                      {
                        row.status.toUpperCase() === "COLLECTED" ? "Collected" : "Pending"
                      }
                    </div>
                  </Buttons>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>
                    <PopoverMenu
                      data={rows}
                      editId="PATIENT_VISIT_EDIT"
                      deleteId="PATIENT_VISIT_DELETE"
                      permission={data}
                      handleDelete={() => handleDelete(row._id)}
                      handleEdit={() => handleEdit(row._id)}
                    // visit={true}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

export default Visitor;
