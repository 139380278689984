import { configureStore } from '@reduxjs/toolkit'
import findPatientSlice from './findPatientSlice';
import userSlice from './userSlice';
import pcDetails from './pcDetails';

const store = configureStore({
  reducer: {
    findPatient: findPatientSlice,
    user: userSlice,
    pcDetails: pcDetails
  },
})

export default store;