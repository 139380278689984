import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import PopoverMenu from "../../Components/Shared/Popover";
import Buttons from "../../Components/Shared/Buttons";
import { UserSvg } from "../../Components/Shared/UserSvg";
import { FiSearch } from 'react-icons/fi'
import AccessRight from "../Accounts/AccessRight";
import { useSelector } from "react-redux";
import { isAllowed } from "../../helper/helper";



const User = () => {
  const tableclasses = tableStyles();
  const navigate = useNavigate();

  const [rows, setRows] = useState();
  const { data, isLoggedIn } = useSelector(state => state.user)
  const [newData, setNewData] = useState(false);

  const [search, setSearch] = useState("");
  const TOKEN = localStorage.getItem("logintoken");

  const fetchData = async () => {
    const data = await axios.get(`${API}/getuserlist?search=${search}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setRows(data.data.users);
  };

  const exportToExcel = () => {
    let data = []

    let clonedRows = [...rows]

    for (let i = 0; i < clonedRows.length; i++) {
      const item = clonedRows[i];
      delete item["password"];
      delete item["__v"];

      data.push({ ...item, phone: item.phone })
    }

    const worksheet = XLSX.utils.json_to_sheet(rows);
    Object.keys(worksheet).forEach(function (s) {
      if (worksheet[s].t === 'n') {
        worksheet[s].z = '0';
        worksheet[s].t = 's';
      }
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  useEffect(() => {
    fetchData();
  }, [newData, search]);

  useEffect(() => {
    if (!TOKEN) {
      navigate("/");
    }
  }, [TOKEN]);

  const handleEdit = (id) => {
    navigate(`/edit-user/${id}`);
  };

  const handleView = (id) => {
    navigate(`/view-user/${id}`);
  }

  const handleDelete = async (id) => {
    const data = await axios.delete(`${API}/delete-user/${id}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    if (data.data.message === "User removed successfully") {
      setNewData(true);
      toast.success("User removed successfully");
      setNewData(false);
    }
  };

  const header = [
    "Name",
    "ID",
    "Email",
    " Phone number",
    "Audit lock days",
    "Status",
    "Options",
  ];

  return (
    <div className={tableclasses.root}>
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.h2}>List of Users/Operators</div>
            <div className={tableclasses.specification}>
              {rows?.length} available doctors
            </div>
          </div>
          <div>
            {
              isAllowed(data.permissions, "ACCOUNT_USER_ADD", data.role) &&
              <Buttons
                className={tableclasses.addButton}
                onClick={() => navigate("/add-user")}
              >
                <UserSvg /> &nbsp; Add new user
              </Buttons>
            }

          </div>
        </div>

        <div className={tableclasses.filterSearch}>
          <div>
            {/* <Button className={tableclasses.filterButton1}>Options</Button> */}
            <Buttons
              className={tableclasses.printButton2}
              onClick={exportToExcel}
            >
              Export
            </Buttons>
            {/* <Button className={tableclasses.filterButton2}>Edit</Button>
            <Button className={tableclasses.filterButton2}>Delete</Button>
            <Button className={tableclasses.filterButton3}>Access right</Button> */}
          </div>

          <div className={tableclasses.searchContainer}>
            <FiSearch className={tableclasses.searchIcon} />
            <TextField
              className={tableclasses.searchField}
              placeholder="Search"
              variant="standard"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <Table className={tableclasses.table}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              {header.map((header, i) => {
                return (
                  <TableCell className={tableclasses.customTableCell}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row._id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                >
                  <div className={tableclasses.profile}>
                    {/* <div>
                      <img alt={row.name} src="../images/Rectangle.png" />
                    </div> */}
                    <div className={tableclasses.name}>
                      <div>{row.name}</div>
                      {/* <div className={tableclasses.specification}>
                        {row.specification}
                      </div> */}
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row._id}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.email}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.phone}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.auditlockdays}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <Buttons className={row.status === "Active" ? tableclasses.customActive : tableclasses.customInActive}>
                    <div>{row.status}</div>
                  </Buttons>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>
                    <PopoverMenu
                      data={rows}
                      handleEdit={() => handleEdit(row._id)}
                      handleDelete={() => handleDelete(row._id)}
                      handleAccessRight={() => navigate(`/AccessRight/${row._id}`)}
                      handleView={() => handleView(row._id)}
                      permission={data}
                      editId="ACCOUNT_USER_EDIT"
                      deleteId="ACCOUNT_USER_DELETE"
                      viewId="ACCOUNT_USER_VIEW"
                      accessRightId="ACCOUNT_USER_ACCESS_RIGHT"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default User;
