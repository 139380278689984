import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "../../Components/Shared/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { accountDoctorPermission, accountSampleCollectorPermission, accountSignaturePermission, accountUserPermission, branchPermission, masterMacroPermission, patientCardsPermission, patientsPermission, patientVisitsPermission, pricelistPermission, reportFormatPermission, reportGroupPermission, testPermission } from '../../helper/data';
import Api from '../../helper/api';
import { toast } from 'react-toastify';

const AccessRight = () => {
  const tableclasses = tableStyles();
  const api = new Api();
  const classes = formStyles();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userPermission, setUserPermission] = useState({})
  const [loading, setLoading] = useState(false);
  const id = useParams().id

  useEffect(() => {
    const fetchUser = async (id) => {
      try {
        const { data, status } = await api.getPermission({
          userId: id
        })

        if (status === 200) {
          setUser(data.data)
          if (data?.data.permissions) {
            setUserPermission(data.data.permissions)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (id) {
      fetchUser(id)
    }
  }, [id])

  const handleAccess = () => {

  }

  const savePermission = async () => {
    try {
      setLoading(true)
      const { data, status } = await api.updatePermission({
        userId: id,
        permissions: userPermission
      })

      if (status === 200) {
        setLoading(false)
        toast.success("Saved Successfully")
        navigate("/register-user")
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const permissionObject = {
    "PATIENTS": patientsPermission,
    "PATIENT_CARDS": patientCardsPermission,
    'PATIENT_VISITS': patientVisitsPermission,
    "REPORT_GROUP": reportGroupPermission,
    "TEST": testPermission,
    "REPORT_FORMAT": reportFormatPermission,
    "PRICE_LIST": pricelistPermission,
    "ACCOUNT": branchPermission,
    "MASTER_MACRO": masterMacroPermission,
    "ACCOUNT_DOCTOR": accountDoctorPermission,
    "ACCOUNT_USER": accountUserPermission,
    "ACCOUNT_SAMPLE_COLLECTOR": accountSampleCollectorPermission,
    "ACCOUNT_SIGNATURE": accountSignaturePermission
  }

  const handlePermission = (e, permission) => {
    if (e.target.checked) {
      setUserPermission((prev) => {
        return { ...prev, [permission[0]]: 1, [permission[1]]: 1 }
      })
    } else {
      let noOfPermission = -1;
      permissionObject[permission[1]]?.forEach((pem) => {
        const permissionExist = userPermission[pem.id]

        if (permissionExist) {
          noOfPermission = noOfPermission + 1
        }
      })

      setUserPermission((prev) => {
        return { ...prev, [permission[0]]: 0, [permission[1]]: noOfPermission > 0 ? 1 : 0 }
      })
    }
  }

  return (
    <div className={tableclasses.root}>
      <div className={tableclasses.body}>
        <div className={tableclasses.backgroundDull}>
          <div className={tableclasses.accessHeader}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.access}>Access Right</div>
              <div className={tableclasses.operator}>operator: {user?.name}</div>
            </span>
            <div style={{ display: "flex", gap: 10 }}>
              <Buttons className={classes.backToButton} disabled={loading} style={{ width: '120px', background: "#b82c3a" }}
                onClick={savePermission}
              >
                {loading ? "saving.." : "Save"}
              </Buttons>

              <Buttons className={classes.backToButton} style={{ width: '120px' }}
                onClick={() => navigate("/register-user")}
              >Back to Table</Buttons>
            </div>
          </div>
          <div style={{ paddingTop: '29px', display: 'flex' }}>
            <input type="radio" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
            <p className={tableclasses.radio} style={{ color: '#181c32' }}>Select all</p>
            <br />
            <input type="radio" style={{ width: '18px', height: '18px', marginLeft: '31px', cursor: 'pointer' }} />
            <p className={tableclasses.radio} style={{ color: '#181c32' }}>Unselect all</p>
          </div>
          <div className={tableclasses.borderbg}>
            <p style={{ fontWeight: '500', fontSize: '14px' }}>All Menus</p>

            <div style={{ paddingTop: '21px', display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" className={tableclasses.sizecheckbox} />
              <p className={tableclasses.radio}>Patients</p>
            </div>

            <div style={{ paddingTop: '13px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Reception/patients</p>
            </div>


            {
              patientsPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "PATIENTS"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }

            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Patient cards</p>
            </div>

            {
              patientCardsPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "PATIENT_CARDS"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }

            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Visits</p>
            </div>

            {
              patientVisitsPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "PATIENT_VISITS"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }


            <div style={{ paddingTop: '15px', display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" className={tableclasses.sizecheckbox} />
              <p className={tableclasses.radio}>Master data</p>
            </div>

            <div style={{ paddingTop: '10px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Report Group</p>
            </div>

            {
              reportGroupPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "REPORT_GROUP"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }

            <div style={{ paddingTop: '10px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Test</p>
            </div>

            {
              testPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "TEST"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Report format</p>
            </div>

            {
              reportFormatPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "REPORT_FORMAT"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Price list</p>
            </div>

            {
              pricelistPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "PRICE_LIST"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Accounts</p>
            </div>

            {
              branchPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "ACCOUNT"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
            {/* <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Sample from</p>
            </div> */}

            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Macro</p>
            </div>

            {
              masterMacroPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "MASTER_MACRO"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }

            <div style={{ paddingTop: '15px', display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" className={tableclasses.sizecheckbox} />
              <p className={tableclasses.radio}>Accounts</p>
            </div>

            <div style={{ paddingTop: '10px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Doctor</p>
            </div>

            {
              accountDoctorPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "ACCOUNT_DOCTOR"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>User/Reception</p>
            </div>

            {
              accountUserPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "ACCOUNT_USER"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Sample Collectors</p>
            </div>

            {
              accountSampleCollectorPermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "ACCOUNT_SAMPLE_COLLECTOR"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
            <div style={{ paddingTop: '12px', paddingLeft: '15px', display: 'flex', alignItems: 'center' }}>
              <div className={tableclasses.checkbox}>+</div>
              <input type="checkbox" className={tableclasses.sizecheckbox} style={{ marginLeft: '14px' }} />
              <p className={tableclasses.radio}>Signature</p>
            </div>

            {
              accountSignaturePermission.map((permission) => {
                return (
                  <div style={{ paddingTop: '15px', paddingLeft: '14px', display: 'flex' }}>
                    <input type="checkbox" checked={userPermission[permission.id]} onChange={(e) => handlePermission(e, [permission.id, "ACCOUNT_SIGNATURE"])} className={tableclasses.sizecheckbox} style={{ marginLeft: '52px' }} />
                    <p className={tableclasses.radio}>{permission.title}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessRight
