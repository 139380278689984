import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import { useReactToPrint } from "react-to-print";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useFormik } from "formik";
import * as Yup from "yup";
import Api from "../../helper/api";
import Select, { components, } from "react-select"
import MasterMacro from "../../Components/Shared/MasterMacro";
import DeleteMacro from "../Accounts/DeleteMacro";
import FormData from "form-data";
import { useSelector } from "react-redux";
import { isAllowed } from "../../helper/helper";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

const Signature = () => {
    const tableclasses = tableStyles();
    const api = new Api();
    const navigate = useNavigate();
    const [values, setValues] = useState([]);
    const [group, setGroup] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [activeSign, setActiveSign] = useState({
        _id: "",
        "name": "",
        "sign": ""
    });
    // const []
    const { data, isLoggedIn } = useSelector(state => state.user)
    const [action, setAction] = useState({
        addNew: false,
        edit: false,
        delete: false
    })

    useEffect(() => {
        fetchData();
    }, [refresh]);

    const fetchData = async () => {
        try {
            const { data, status } = await api.signature().get("/signature")

            if (status === 200) {
                setValues(data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className={tableclasses.root}>

            <div className={tableclasses.body}>
                <div className={tableclasses.header}>
                    <div className={tableclasses.name}>
                        <div className={tableclasses.h2}>Signature </div>
                    </div>

                    <div>

                        {
                            isAllowed(data.permissions, "ACCOUNT_SIGNATURE_ADD", data.role) &&
                            <Buttons className={tableclasses.printButton} onClick={() => setAction((prev) => { return { ...prev, addNew: true } })}>
                                Add
                            </Buttons>
                        }

                    </div>
                </div>

                <div style={{ width: "94%", minHeight: "100vh", display: "flex", flexWrap: "wrap", marginBottom: 8, gap: 15 }}>
                    {
                        values.map((sign) => (
                            <div style={{ width: 150, position: "relative", height: 150, padding: 4, borderRadius: 4, border: "1px solid grey", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)" }}>
                                <img src={sign.sign} alt="" style={{ width: "100%", height: "calc(100% - 20px)", objectFit: "cover" }} />
                                <h5 style={{ textAlign: "center", fontWeight: 500 }}>{sign.name}</h5>

                                {
                                    isAllowed(data.permissions, "ACCOUNT_SIGNATURE_EDIT", data.role) &&
                                    <div style={{ position: "absolute", top: 5, right: 5, cursor: "pointer" }} onClick={() => {
                                        setActiveSign(sign)
                                        setAction((prev) => {
                                            return { ...prev, edit: true }
                                        })
                                    }}>
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0107 0.827649C16.9072 -0.275878 15.1181 -0.275888 14.0145 0.827649L12.4323 2.40989L4.98547 9.85667C4.86475 9.97743 4.77912 10.1287 4.73772 10.2943L3.7958 14.062C3.71555 14.383 3.8096 14.7225 4.04355 14.9564C4.27751 15.1904 4.61706 15.2845 4.93804 15.2042L8.70568 14.2623C8.87136 14.2209 9.02254 14.1353 9.1433 14.0145L16.5359 6.6219L18.1723 4.98549C19.2759 3.88196 19.2759 2.09278 18.1723 0.989254L18.0107 0.827649ZM15.3466 2.15972C15.7144 1.79188 16.3108 1.79188 16.6786 2.15972L16.8403 2.32133C17.2081 2.68918 17.2081 3.28557 16.8403 3.65341L15.8828 4.6109L14.4178 3.08857L15.3466 2.15972ZM13.0854 4.42089L14.5505 5.94321L7.99576 12.498L6.00414 12.9959L6.50202 11.0043L13.0854 4.42089ZM1.88384 5.81314C1.88384 5.29293 2.30555 4.87121 2.82575 4.87121H7.53535C8.05557 4.87121 8.47726 4.44951 8.47726 3.92929C8.47726 3.40909 8.05557 2.98737 7.53535 2.98737H2.82575C1.26514 2.98737 0 4.25251 0 5.81314V16.1742C0 17.7349 1.26514 19 2.82575 19H13.1869C14.7475 19 16.0126 17.7349 16.0126 16.1742V11.4646C16.0126 10.9445 15.5909 10.5227 15.0707 10.5227C14.5505 10.5227 14.1288 10.9445 14.1288 11.4646V16.1742C14.1288 16.6945 13.7071 17.1162 13.1869 17.1162H2.82575C2.30555 17.1162 1.88384 16.6945 1.88384 16.1742V5.81314Z" fill="black" />
                                        </svg>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

            <AddNewMac
                action={action}
                setAction={setAction}
                refresh={() => setRefresh(!refresh)}
                handleClose={() => setAction((prev) => { return { ...prev, addNew: false } })}
            />
            <EditSign
                action={action}
                handleClose={() => {
                    setActiveSign({ name: "", sign: "" })
                    setAction((prev) => { return { ...prev, edit: false } })
                }}
                refresh={() => setRefresh(!refresh)}
                data={activeSign}
            />
            {
                action.delete &&
                <DeleteMacro
                    handleClose={() => setAction((prev) => { return { ...prev, delete: false } })}
                    data={activeSign}
                    refresh={() => setRefresh(!refresh)}
                />
            }

        </div>
    );
};

const AddNewMac = ({ action, handleClose, setAction, refresh }) => {
    const api = new Api();
    const tableclasses = tableStyles();
    const [values, setValues] = useState({
        name: "",
        sign: ""
    })
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        try {
            setLoading(true)
            if (!values.name || !values.sign) {
                toast.error("All fields are required")
                setLoading(false)
                return null
            }

            const { data, status } = await api.signature().post("/signature", {
                ...values
            })

            if (status === 200) {
                toast.success("Saved successfully.")
                setLoading(false)
                refresh();
                setAction((prev) => {
                    return {
                        ...prev
                    }
                })
                handleClose();
            }

        } catch (err) {
            setLoading(false);
        }
    }

    const handleImg = async (e) => {
        try {
            const img = e.target.files[0];
            const formData = new FormData()
            formData.set("image", img);

            const { data, status } = await api.signature().post("/upload-file",
                formData
            )

            if (status === 200) {
                setValues((prev) => {
                    return { ...prev, sign: data[0].src }
                })
            }

        } catch (err) {
            console.log(err)
        }
    }

    const handleName = (e) => {
        e.persist();
        setValues((value) => { return { ...value, name: e.target.value } })
    }
    return (
        <Modal
            open={action.addNew}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h6 style={{ fontWeight: 600 }}>
                        Name:
                    </h6>
                    <input type="text" name="name" value={values.name} onChange={handleName} style={{ border: "1px solid hsl(0, 0%, 80%)", outline: 'none', width: "100%", padding: 9, borderRadius: 4 }} />
                </div>

                <div style={{ margin: "10px 0" }}>
                    <h6 style={{ fontWeight: 600 }}>
                        Signature:
                    </h6>
                    <input type="file" name="" id="" style={{ marginBottom: 10 }} accept="image/*" onChange={handleImg} />

                    {
                        values.sign &&
                        <img src={values.sign} style={{ width: 150, height: 120, objectFit: "cover" }} />
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Buttons className={tableclasses.printButton} disabled={loading} style={{ width: "120px" }} onClick={handleSave}>
                        {
                            loading ? "Saving.." : "Save"
                        }
                    </Buttons>
                    <Buttons className={tableclasses.printButton} style={{ width: "120px", background: "#fff", color: "#000" }} onClick={handleClose}>
                        Cancel
                    </Buttons>
                </div>
            </Box>
        </Modal>
    )
}

const EditSign = ({ action, handleClose, refresh, data }) => {
    const api = new Api();
    const tableclasses = tableStyles();
    const [values, setValues] = useState({
        name: "",
        sign: ""
    })
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data?._id) {
            setValues({
                name: data.name,
                sign: data.sign
            })
        }
    }, [data?._id])

    const handleSave = async () => {
        try {
            setLoading(true)
            if (!values.name || !values.sign) {
                toast.error("All fields are required")
                setLoading(false)
                return null
            }

            const { status } = await api.signature().put(`/signature/${data._id}`, {
                ...values
            })

            if (status === 200) {
                toast.success("Edited successfully.")
                setLoading(false)
                refresh();
                handleClose();
            }

        } catch (err) {
            setLoading(false);
        }
    }

    const handleImg = async (e) => {
        try {
            const img = e.target.files[0];
            const formData = new FormData()
            formData.set("image", img);

            const { data, status } = await api.signature().post("/upload-file",
                formData
            )

            if (status === 200) {
                setValues((prev) => {
                    return { ...prev, sign: data[0].src }
                })
            }

        } catch (err) {
            console.log(err)
        }
    }

    const handleName = (e) => {
        e.persist();
        setValues((value) => { return { ...value, name: e.target.value } })
    }
    return (
        <Modal
            open={action.edit}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <h6 style={{ fontWeight: 600 }}>
                        Name:
                    </h6>
                    <input type="text" name="name" value={values.name} onChange={handleName} style={{ border: "1px solid hsl(0, 0%, 80%)", outline: 'none', width: "100%", padding: 9, borderRadius: 4 }} />
                </div>

                <div style={{ margin: "10px 0" }}>
                    <h6 style={{ fontWeight: 600 }}>
                        Signature:
                    </h6>
                    <input type="file" name="" id="" style={{ marginBottom: 10 }} accept="image/*" onChange={handleImg} />

                    {
                        values.sign &&
                        <img src={values.sign} style={{ width: 150, height: 120, objectFit: "cover" }} />
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Buttons className={tableclasses.printButton} disabled={loading} style={{ width: "120px" }} onClick={handleSave}>
                        {
                            loading ? "Saving.." : "Save"
                        }
                    </Buttons>
                    <Buttons className={tableclasses.printButton} style={{ width: "120px", background: "#fff", color: "#000" }} onClick={handleClose}>
                        Cancel
                    </Buttons>
                </div>
            </Box>
        </Modal>
    )
}



export default Signature;
