import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import {
  TableCell
} from "@material-ui/core";
import { useNavigate, useParams } from 'react-router-dom';
import Buttons from "../../Components/Shared/Buttons";
import Api from '../../helper/api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import * as Yup from "yup";
import { useFormik } from 'formik';
import Input from '../../Components/Shared/Input';


const ViewUser = () => {
  const api = new Api();
  const params = useParams();
  const [data, setData] = useState(null);
  const tableclasses = tableStyles();
  const classes = formStyles();
  const navigate = useNavigate();
  const [assign, setAssign] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [passOverlay, setPassOverlay] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, status } = await api.fetchUserByID({}, { id: params.id })

        if (status === 200) {
          setData(data.singleuser)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchUser();
  }, [refresh])
  return (
    <div className={tableclasses.root} style={{ margin: "25px" }}>
      <div className={tableclasses.appointmentbody}>
        <div className={tableclasses.headertwo}>
          <div className={classes.formname}>
            <div className={classes.formh2}>{data?.name}</div>
            <div className={classes.viewHead}>
              <div className={`${classes.formspecification}`}
                style={{ marginLeft: '50px', marginRight: '50px' }}
              >
                User ID: {params?.id}
              </div>
              <div className={classes.formspecification} style={{ display: 'flex', alignItems: 'center' }}>
                Status:
                <TableCell className={tableclasses.customTableCell}>
                  {
                    data?.status === "active" || data?.status === "Active" ?
                      <Buttons className={tableclasses.customActive}>
                        <div style={{ color: 'green' }}>
                          Active
                        </div>
                      </Buttons>
                      :
                      <Buttons className={tableclasses.customInActive}>
                        <div style={{ color: 'red' }}>
                          InActive
                        </div>
                      </Buttons>
                  }
                </TableCell>
              </div>
              <Buttons
                className={classes.backToButton}
                style={{ width: "120px", background: "#B82C3A", lineHeight: "15px" }}
                onClick={() => setAssign(true)}
              >
                Assign signature
              </Buttons>
            </div>
            <Buttons
              className={classes.backToButton}
              style={{ width: "120px" }}
              onClick={() => navigate("/register-user")}
            >
              Back to Table
            </Buttons>
          </div>
        </div>
        <div className={classes.biodata}>
          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Name</p>
              <p>Email</p>
              <p>Phone</p>
              <p>Role</p>
              <p>AuditLock Days</p>
              <p>Signature</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
              <p>{data?.name ? data?.name : "NA"}</p>
              <p>{data?.email ? data?.email : "NA"}</p>
              <p>{data?.phone ? data?.phone : "NA"}</p>
              <p>{data?.role ? data?.role : "NA"}</p>
              <p>{data?.auditlockdays ? data?.auditlockdays : "NA"}</p>
              {
                data?.sign &&
                <img src={data.sign.sign} style={{ width: 150, height: 120, objectFit: "cover" }} />
              }
            </div>
          </div>

          <div className={classes.imageside}></div>
        </div>
        <Buttons
          className={classes.backToButton}
          style={{ width: "120px", background: "#B82C3A", lineHeight: "15px", marginBottom: 10 }}
          onClick={() => setPassOverlay(true)}
        >
          Change Password
        </Buttons>
      </div>
      <AssignSignature setRefresh={setRefresh} refresh={refresh} id={params.id} setAssign={setAssign} assign={assign} />
      {
        passOverlay &&
        <OverLayPassword userId={params.id} handleClose={() => setPassOverlay(false)} />
      }
    </div>
  )
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};


const AssignSignature = ({ setAssign, assign, id, setRefresh, refresh }) => {
  const tableclasses = tableStyles();
  const [loading, setLoading] = useState(false);
  const [sign, setSign] = useState([]);
  const api = new Api();
  const [activeSign, setActiveSign] = useState({})

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data, status } = await api.signature().get("/signature")

        if (status === 200) {
          setSign(data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetch();
  }, [])

  const handleSave = async () => {
    if (!activeSign?._id) {
      toast.error("Please select the signature")
      return null
    }

    try {
      const { status } = await api.signature().put(`/edit-user/${id}`, {
        sign: activeSign._id
      })

      if (status === 200) {
        toast.success("Saved successfully")
        setRefresh(!refresh)
        setAssign(false)
      }
    } catch (err) {
      toast.error("Some error occured!")
    }
  }
  return (
    <Modal
      open={assign}
      onClose={() => setAssign(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

        <div style={{ width: "94%", display: "flex", flexWrap: "wrap", marginBottom: 8, gap: 15 }}>
          {
            sign.map((sign) => (
              <div onClick={() => setActiveSign(sign)} style={{ width: 150, position: "relative", height: 150, padding: 4, borderRadius: 4, border: activeSign._id === sign._id ? "2px solid red" : "1px solid grey", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)" }}>
                <img src={sign.sign} alt="" style={{ width: "100%", height: "calc(100% - 20px)", objectFit: "cover" }} />
                <h5 style={{ textAlign: "center", fontWeight: 500 }}>{sign.name}</h5>
              </div>
            ))
          }
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Buttons className={tableclasses.printButton} disabled={loading} style={{ width: "120px" }} onClick={handleSave}>
            {
              loading ? "Saving.." : "Save"
            }
          </Buttons>
          <Buttons className={tableclasses.printButton} style={{ width: "120px", background: "#fff", color: "#000" }} onClick={() => setAssign(false)}>
            Cancel
          </Buttons>
        </div>
      </Box>
    </Modal>
  )
}

const OverLayPassword = ({ userId, handleClose }) => {
  const classes = formStyles();
  const baseStyles = "px-4 py-2 rounded-lg font-medium transition-all mr-4 mt-2";
  const variants = {
    default: "bg-red-600 text-white hover:bg-red-700",
    outline: "border border-gray-400 text-gray-700 hover:bg-gray-100",
  };
  const [loading, setLoading] = useState(false);
  const api = new Api();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password and confirmpassword  must match')
      .required('Confirm password is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)

        const { data, status } = await api.resetUserPass({
          userId,
          ...values
        })

        if (status === 200) {
          toast.success("Password updated successfully")
          setLoading(false)
          handleClose();
        }
      } catch (e) {
        console.log(e);
        toast.success("Some error occurred")
        setLoading(false)
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 absolute z-50">
      <div
        className="bg-white p-6 rounded-2xl shadow-xl space-y-4"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div>
          <div className={classes.formLable}>Password</div>
          <Input
            type="password"
            placeholder="Enter password"
            className={classes.formInput}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password && touched.password && (
            <div style={{ color: "red", fontSize: '13px' }}>{errors.password}</div>
          )}
        </div>
        <div>
          <div className={classes.formLable}>Confirm Password</div>
          <Input
            type="password"
            placeholder="Enter password"
            className={classes.formInput}
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.confirmPassword && touched.confirmPassword && (
            <div style={{ color: "red", fontSize: '13px' }}>{errors.confirmPassword}</div>
          )}
        </div>
        <div className="flex justify-center gap-4">
          <button className={`${baseStyles} ${variants["default"]}`} onClick={handleClose} variant="outline">Cancel</button>
          <button className={`${baseStyles} ${variants["outline"]}`} onClick={handleSubmit} variant="default">
            {
              loading ? <CircularProgress size={18} /> : "Save"
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewUser
