import axios from "axios";
import FormData from "form-data";
import { API } from "../config";

const formData = new FormData()

let token = {};

export default class Api {
    constructor() {
        token = localStorage.getItem("logintoken");
        this.client = null;
        this.api_url = process.env.NODE_ENV === "production" ? 'https://backend.nagalikardiagnostic.com/api' : 'http://localhost:8000/api';
    }

    init = (type) => {

        let headers;

        if (type === "multipart/form-data") {
            headers = {
                'Content-Type': 'multipart/form-data',
            }
        }
        else {
            headers = {
                Accept: "application/json",
            }
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        this.client.interceptors.request.use((config) => {
            config.headers["authtoken"] = `${token}`
            return config;
        }, (error) => {
            throw error;
        })

        return this.client;
    };

    // -------- Operator/user--------
    // --------------------------
    fetchUser = (data, config) => {
        return this.init().get("/getuserlist", data, config)
    }

    fetchUserByID = (data, config) => {
        return this.init().get(`/getuserlist/${config.id}`, data, config)
    }

    whoAmI = (data, config) => {
        return this.init().get("whoAmI")
    }

    getPermission = (data, config) => {
        return this.init().post("/user/permission", data)
    }

    updatePermission = (data, config) => {
        return this.init().put("/user/permission", data)
    }

    resetUserPass = (data, config) => {
        return this.init().put("/reset-pass-user", data)
    }

    // -------- Pricelist--------
    // --------------------------

    fetchPriceList = (data, config) => {
        return this.init().get(`/getPriceList/${config.page}/${config.quantity}`, data, config)
    }

    addPriceList = (data, config) => {
        return this.init().post("/doctor/priceList", data, config)
    }

    fetchDoctorPriceList = (data, config) => {
        return this.init().get(`/doctor/priceList?page=${data.page}&limit=${data.limit}&doctor=${data.doctor}&name=${data.name}&account=${data.account}`)
    }

    editPriceList = (data, config) => {
        return this.init().put("/doctor/priceList", data, config)
    }

    deletePriceList = (data, config) => {
        return this.init().delete(`/doctor/priceList?doctor=${data.doctor}&priceList=${data.priceList}`, data, config)
    }

    fetchDetailedPrice = (data, config) => {
        return this.init().get(`/getDetailedPrice/${config.id}`)
    }

    // -------- Upload files--------
    // --------------------------
    uploadSlip = (data, config) => {
        formData.set("image", data.image)
        return this.init("multipart/form-data").post(`/upload-file?id=${config.id}`, formData, config)
    }

    uploadReport = (data, config) => {
        return this.init("multipart/form-data").post(`/upload-report?id=${config.id}`, data, config)
    }

    updatePatientSlip = (data, config) => {
        return this.init().put(`update-patient-slip/${config.id}`, data)
    }

    // --------Patient --------
    // --------------------------

    registerPatient = (data, config) => {
        return this.init().post("/register-patient", data, config)
    }

    getPatientList = (data, config) => {
        return this.init().get(`/getpatiencelist/${config.page}/1000000000000?date=${JSON.stringify(config.date)}&status=${config.status}&category=${JSON.stringify(config.category)}&search=${config.search}&account=${config.account}`)
    }

    fetchPatient = (data, config) => {
        return this.init().post(`/find-patient/${config.page}/${config.quantity}`, data, config)
    }

    fetchCity = (data, config) => {
        return this.init().get("/fetch-city-list", data, config)
    }

    fetchPatientById = (data, config) => {
        return this.init().get(`/get-patience/${config.id}`)
    }

    fetchPatientCardById = (data, config) => {
        return this.init().get(`/get-patient-card/${config.id}`, data, config)
    }

    fetchPatientInCard = (data, config) => {
        return this.init().get(`/`)
    }

    deletePatient = (data, config) => {
        return this.init().delete(`/delete-patience/${config.id}`)
    }

    updatePatientReport = (data, config) => {
        return this.init().post("/update-patient-report", data, config)
    }

    updateReceiptPrintedCount = (data, config) => {
        return this.init().post(`/receipt-printed-count/${config.id}/${config.paymentNo}`)
    }

    deletePatientPayment = (data, config) => {
        return this.init().delete(`/delete-patient-payment/${config.id}/${config.paymentNo}`)
    }

    updatePatient = (data, config) => {
        return this.init().put(`/edit-patience/${config.id}`, data, config)
    }

    updateMacro = (data, config) => {
        return this.init().post("/update-macro", data, config)
    }

    updateLayout = (data, config) => {
        return this.init().post("/update-layout", data, config)
    }

    verifyPatientReport = (data, config) => {
        return this.init().post("/verify-patient-report", data, config)
    }


    addNewSample = (data, config) => {
        return this.init().post(`/add-new-sample/${config.id}`, data)
    }

    addNewInvestigation = (data, config) => {
        return this.init().post(`/add-new-investigation/${config.id}`, data)
    }

    editPatientSample = (data, config) => {
        return this.init().post(`/edit-patient-sample/${config.id}/${config.sample}`, data)
    }

    addNewPayment = (data, config) => {
        return this.init().post(`/add-new-payment/${config.id}`, data)
    }

    editPatientPayment = (data, config) => {
        return this.init().post(`/edit-patient-payment/${config.id}/${config.payment}`, data)
    }

    deleteInvestigation = (data, config) => {
        return this.init().delete(`/delete-investigation/${config.id}/${config.investigation}`)
    }

    updateInvestigationStatus = (data, config) => {
        return this.init().put(`/update-investigation-status/${config.id}/${config.investigation}`, data)
    }

    sendRepobyEmail = (data, config) => {
        return this.init().post("/send-report-by-email", data, config)
    }

    sendRepobySMS = (data, config) => {
        return this.init().post("/send-report-by-sms", data, config)
    }

    updateReportPdf = (data, config) => {
        return this.init().post("/update-report-pdf", data)
    }

    // -------- Accounts--------
    // --------------------------
    fetchAccount = (data, config) => {
        return this.init().get(`/getaccountdetails/${config?.page}/${config?.quantity}`, data, config)
    }

    // -------- Doctors--------
    // --------------------------

    fetchDoctors = (data, config) => {
        return this.init().get("/getdoctorlist", data, config)
    }

    fetchDoctorById = (data, config) => {
        return this.init().get(`/getdoctor/${data}`)
    }

    deleteDoctor = (data, config) => {
        return this.init().delete(`/delete-doctor/${config.id}`)
    }

    // -------- Report group--------
    // --------------------------

    fetchReportGroup = (data, config) => {
        return this.init().get("/gettestcategory", data, config)
    }

    fetchReportById = (data, config) => {
        return this.init().get(`/getreportformat/${config.id}`)
    }

    addReportFormat = (data, config) => {
        return this.init().post("/addreportformat", data, config)
    }

    putReq = (url, data) => {
        return this.init().put(url, data)
    }
    // -------- sample collectors--------
    // --------------------------

    fetchPatientCollectors = (data, config) => {
        return this.init().get(`/getPatientCollectors/${config.page}/${config.quantity}?date=${config.date}&location=${JSON.stringify(config.location)}&status=${config.status}&search=${config.search}`, data, config)
    }

    deletePatientCollectors = (data, config) => {
        return this.init().delete(`/delete-patient-collector/${config.id}`)
    }


    // test -----------------------------
    // ------------------------

    updateTest = (data, config) => {
        return this.init().put(`/edittestsubcategory/${config.id}`, data, config)
    }

    fetchTestById = (data, config) => {
        return this.init().get(`/fetch-test/${config.patient}/${config.investigationId}`)
    }

    uploadTestExcel = (data, config) => {
        formData.set("file", data)
        return this.init("multipart/form-data").post("/add-test-by-excel", formData, config)
    }

    downloadReport = (data, config) => {
        return this.init().get(`/download-report/${config.id}/${config.reportId}`)
    }

    getAllTest = (data, config) => {
        return this.init().get("/gettestsubcategory/1/100000")
    }

    // payment ---------------------
    // ------------------------------
    fetchPayment = (data, config) => {
        return this.init().post('/payment', data)
    }

    fetchDoctorWisePayment = (data, config) => {
        return this.init().post("/doctor-wise-payment", data)
    }

    macro = () => {
        return this.init()
    }

    // Branch details
    getAllPc = () => {
        return this.init().get("/get-all-pc")
    }

    getPcDetails = (data) => {
        return this.init().post("/get-pc", data)
    }

    updatePcDetails = (data) => {
        return this.init().put("/update-pc", data)
    }

    deletePcDetails = (data) => {
        return this.init().post("/delete-pc", data)
    }

    signature = () => {
        return this.init()
    }
}