import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import Buttons from "../../Components/Shared/Buttons";
import { DropdownSvg, EnteredSvg, FilterSvg, PendingSvg, ResetFilterSvg, VerifiedSvg } from "../../Components/Shared/UserSvg";
import PopoverMenu from "../../Components/Shared/Popover";
import { API } from "../../config";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import Api from "../../helper/api";
import { toast } from "react-toastify";
import FilterCategory from "../MasterData/FilterCategory";

const PatientSample = () => {
  const api = new Api()
  const tableclasses = tableStyles();
  const [search, setSearch] = useState("")
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState();
  const [sortByDateAsc, setSortByDateAsc] = useState(true);
  const [retry, setRetry] = useState(false);
  const [filter, setFilter] = useState({
    date: null,
    status: null,
    location: null
  });
  const [filterData, setFilterData] = useState({
    date: [],
    status: "",
    location: []
  })

  const status = ["Pending", "Received", "Collected"]

  const handleReset = () => {
    setFilterData({
      date: "",
      status: "",
      location: []
    })
  }

  const reportContent = useRef()
  const navigate = useNavigate();

  const handleDelete = async (id) => {
    try {
      const { data, status } = await api.deletePatientCollectors({}, { id })

      if (status === 200) {
        setRetry(!retry)
        toast.success("Deleted successfully")
      }
    } catch (err) {
      toast.error("Error while deleting!")
    }
  }

  const handleView = async (id) => {
    navigate(`/view-sample-patient/${id}`)

  }
  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
    // onAfterPrint:alert("Pdf Downloaded")
  })


  const formatedDate = (newDate) => {
    const date = new Date(newDate);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const formatTime = (newTime) => {
    const time = new Date(newTime);
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const fetchData = async () => {
    try {
      const { date, status, location } = filterData;
      const { data } = await api.fetchPatientCollectors({}, {
        page: page,
        quantity: 10,
        date: JSON.stringify(date),
        status,
        location,
        search
      })

      setRows(data.patients);
      setPageInfo(data);
    } catch (error) {
      console.error("Fetching Data Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, retry, search, filterData.date, filterData.location, filterData.status]);

  const setNextPage = () => {
    if (pageInfo?.currentPage > 0) {
      if (page === pageInfo?.totalPages) return;
      setPage(page + 1);
    }
  };

  const setPrevPage = () => {
    if (pageInfo.currentPage > 1) {
      setPage(page - 1);
    }
  };

  const header = [
    "Collection name",
    "Location",
    "Patient name & no",
    "Date & Time",
    "Lab No",
    "Reffered By",
    "Sample",
    "Status",
    "Actions",
  ];

  return (
    <div className={tableclasses.root}>
      <FilterCategory status={status} setFilter={setFilter} filter={filter}
        filterData={filterData} setFilterData={setFilterData} />
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.h2}>Sample collections</div>
            <div className={tableclasses.specification}>
              {pageInfo?.totalItems} sample collection
            </div>
          </div>
          {/* <div>
            <Buttons className={tableclasses.addButton}>
              <DoctorSvg /> &nbsp; Add new Patient
            </Buttons>
          </div> */}
        </div>

        <div className={tableclasses.filterSearch}>
          <div>
            <Buttons className={tableclasses.filterButton1}>
              <FilterSvg />
            </Buttons>
            {/* <Buttons className={tableclasses.filterButton2}>Filter By</Buttons> */}
            <Buttons onClick={(e) => setFilter((prev) => {
              return { ...prev, date: e.currentTarget }
            })} className={tableclasses.filterButton2}>Date
              <DropdownSvg /> </Buttons>
            <Buttons onClick={(e) => setFilter((prev) => {
              return { ...prev, location: e.currentTarget }
            })}
              className={tableclasses.filterButton2}>Location
              <DropdownSvg /> </Buttons>
            <Buttons onClick={(e) => setFilter((prev) => {
              return { ...prev, status: e.currentTarget }
            })}
              className={tableclasses.filterButton2}>
              Sample Status
              <DropdownSvg />
            </Buttons>
            <Buttons
              onClick={handleReset}
              className={tableclasses.filterButton3}>
              <ResetFilterSvg />
              <span style={{ color: "#FF8743", marginLeft: 6, text: "center" }}>
                Reset FIlter
              </span>{" "}
            </Buttons>
            <Buttons className={tableclasses.printButton} onClick={generatePDF}>Print</Buttons>
          </div>

          <div className={tableclasses.searchContainer}>
            <FiSearch className={tableclasses.searchIcon} />
            <TextField
              className={tableclasses.searchField}
              placeholder="Search"
              variant="standard"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <Table className={tableclasses.table} ref={reportContent}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              {header.map((header) => {
                return (
                  <TableCell className={tableclasses.customHeadName}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              let status = "pending";

              let pending = 0;
              let received = 0;
              let collected = 0;

              if (row.sampleCollection?.length > 0) {
                for (let i = 0; i < row?.sampleCollection?.length; i++) {
                  if (row.sampleCollection[i]?.sampleStatus.toUpperCase() === "PENDING") {
                    pending++
                  } else if (row.sampleCollection[i]?.sampleStatus.toUpperCase() === "RECEIVED") {
                    received++
                  } else if (row.sampleCollection[i]?.sampleStatus.toUpperCase() === "COLLECTED") {
                    collected++
                  }
                }

                if (row.sampleCollection?.length === collected) {
                  status = "Collected"
                } else if (row.sampleCollection?.length === received) {
                  status = "Received"
                } else {
                  status = "Pending"
                }
              }
              return (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={tableclasses.customTableCell}
                  >
                    <div className={tableclasses.name}>
                      <div>{row?.collector?.name?.toString()}</div>
                    </div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div className={tableclasses.name}>
                      <div>{row.city}</div>
                    </div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div className={tableclasses.name}>
                      <div>{row.firstName}</div>
                      <div className={tableclasses.specification}>
                        {row.phone}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div>{formatedDate(row.createdAt)}</div>
                    <div className={tableclasses.specification}>
                      {formatTime(row.createdAt)}
                    </div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div>{row?.labNumber ? row?.labNumber : "NA"}</div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div>{row?.refferedBy?.name ? row?.refferedBy?.name : "NA"}</div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div>{row?.sampleCollection[0]?.sampleType}</div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div
                      style={{
                        text: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {
                          status === "Pending"
                            ? <PendingSvg />
                            : status === "Received" ? <EnteredSvg />
                              : <VerifiedSvg />
                        }
                        <span
                          style={{
                            color: status === "Pending"
                              ? "#B82C3A"
                              : status === "Received" ? "#D48A48"
                                : "#60BB47",
                            marginLeft: 6,
                            text: "center",
                          }}
                        >
                          {status}
                        </span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className={tableclasses.customTableCell}>
                    <div>
                      <PopoverMenu
                        data={rows}
                        handleDelete={() => handleDelete(row._id)}
                        handleView={() => handleView(row._id)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <div className={tableclasses.pagination}>
          <div className={tableclasses.name}>
            Showing {rows?.length} of {pageInfo?.totalItems} entries
          </div>
          <div>
            <Buttons onClick={setPrevPage} className={tableclasses.pageButton}>
              Previous
            </Buttons>
            <Buttons className={tableclasses.numButton}>
              {pageInfo?.currentPage}
            </Buttons>
            <Buttons onClick={setNextPage} className={tableclasses.pageButton}>
              Next
            </Buttons>
          </div>
          {/* <div></div> */}
        </div>
      </div>
    </div>
  );
};

export default PatientSample;
