export const labSuffix = [
    {
        value: 20,
        label: 20
    },
    {
        value: 21,
        label: 21
    },
    {
        value: 22,
        label: 22
    },
    {
        value: 23,
        label: 23
    },
    {
        value: 24,
        label: 24
    },
    {
        value: 25,
        label: 25
    }
]

export const gender = [
    {
        value: "male",
        label: "Male"
    },
    {
        value: "female",
        label: "Female"
    }
]

export const sampleFrom = [
    {
        value: "doctor",
        label: "Doctor"
    },
    {
        value: "lab",
        label: "Lab"
    }
]

export const sendRepoBy = [
    {
        value: "email",
        label: "Email"
    },
    {
        value: "phone",
        label: "Phone"
    }
]

export const sampleType = [
    {
        value: "blood",
        label: "Blood"
    },
    {
        value: "urine",
        label: "Urine"
    },
    {
        value: "blood and urine",
        label: "Blood and Urine"
    }
]

export const sampleStatus = [
    {
        value: "collected",
        label: "Collected"
    },
    {
        value: "received",
        label: "Received"
    }, {
        value: "pending",
        label: "Pending"
    }
]

export const paymentMode = [
    {
        value: "",
        label: "All"
    },
    {
        value: "online",
        label: "Online"
    }, {
        value: "cash",
        label: "Cash"
    }
]


export const patientsPermission = [
    {
        id: "PATIENTS_ADD",
        title: "Add Patient"
    },
    {
        id: "PATIENTS_VIEW",
        title: "View Patient"
    },
    {
        id: "PATIENTS_EDIT",
        title: "Edit Patient"
    },
    {
        id: "PATIENTS_DELETE",
        title: "Delete Paitent"
    },
    {
        id: "PATIENTS_ASSIGN",
        title: "Assign Collector"
    },
    {
        id: "PATIENTS_PRINT",
        title: "Print Report"
    },
    {
        id: "INVESTIGATION_ADD",
        title: "Add investigation"
    },
    {
        id: "INVESTIGATION_DELETE",
        title: "Delete Investigation"
    },
    {
        id: "INVESTIGATION_BILL",
        title: "Patient Bill"
    },
    {
        id: "BARCODE_EDIT",
        title: "Edit Barcode"
    },
    {
        id: "BARCODE_PRINT",
        title: "Print Barcode"
    },
    {
        id: "REPORTS_ADD",
        title: "Add Reports"
    },
    {
        id: "REPORTS_EDIT",
        title: "Edit reports"
    },
    {
        id: "REPORTS_LAYOUT",
        title: "Edit Layout"
    },
    {
        id: "REPORTS_ETRY",
        title: "Report entry"
    },
    {
        id: "REPORTS_VERIFY",
        title: "Verify report"
    },
    {
        id: "REPORTS_PREVIEW",
        title: "Preview Reports"
    },
    {
        id: "REPORTS_MACROS",
        title: "Report Macros"
    },
    {
        id: "REPORTS_PRINT",
        title: "Print report"
    },
    {
        id: "REPORTS_SMS",
        title: "Send Report by sms"
    },
    {
        id: "REPORTS_EMAIL",
        title: "Send Report by email"
    },
    {
        id: "REPORTS_IMAGE",
        title: "Report image"
    },
    {
        id: "RECEIPT_ADD",
        title: "Add Receipt"
    },
    {
        id: "RECEIPT_EDIT",
        title: "Edit receipt"
    },
    {
        id: "RECEIPT_DELETE",
        title: "Delete Receipt"
    },
    {
        id: "RECEIPT_PRINT",
        title: "Print Receipt"
    },
]

export const patientCardsPermission = [
    {
        id: "PATIENT_CARD_ADD",
        title: "Add Patient Card"
    },
    {
        id: "PATIENT_CARD_VIEW",
        title: "View Patient Card"
    },
    {
        id: "PATIENT_CARD_EDIT",
        title: "Edit Patient Card"
    },
    {
        id: "PATIENT_CARD_DELETE",
        title: "Delete Patient Card"
    }
]

export const patientVisitsPermission = [
    {
        id: "PATIENT_VISIT_ADD",
        title: "Add Patient Visit"
    },
    {
        id: "PATIENT_VISIT_EDIT",
        title: "Edit Patient Visit"
    },
    {
        id: "PATIENT_VISIT_DELETE",
        title: "Delete Patient Visit"
    }
]

export const reportGroupPermission = [
    {
        id: "REPORT_GROUP_ADD",
        title: "Add Report Group"
    },
    {
        id: "REPORT_GROUP_EDIT",
        title: "Edit Report Group"
    },
    {
        id: "REPORT_GROUP_DELETE",
        title: "Delete Report Group"
    }
]

export const testPermission = [
    {
        id: "TEST_ADD",
        title: "Add Test"
    },
    {
        id: "TEST_EDIT",
        title: "Edit Test"
    },
    {
        id: "TEST_DELETE",
        title: "Delete Test"
    }
]

export const reportFormatPermission = [
    {
        id: "REPORT_FORMAT_ADD",
        title: "Add Report Format"
    },
    {
        id: "REPORT_FORMAT_VIEW",
        title: "View Report Format"
    },
    {
        id: "REPORT_FORMAT_EDIT",
        title: "Edit Report Format"
    },
    {
        id: "REPORT_FORMAT_DELETE",
        title: "Delete Report Format"
    }
]


export const pricelistPermission = [
    {
        id: "PRICELIST_ADD",
        title: "Add PriceList"
    },
    {
        id: "PRICELIST_VIEW",
        title: "View PriceList"
    },
    {
        id: "PRICELIST_EDIT",
        title: "Edit PriceList"
    },
    {
        id: "PRICELIST_DELETE",
        title: "Delete PriceList"
    }
]


export const branchPermission = [
    {
        id: "ACCOUNT_ADD",
        title: "Add Account"
    },
    {
        id: "ACCOUNT_EDIT",
        title: "Edit Account"
    },
    {
        id: "ACCOUNT_DELETE",
        title: "Delete Account"
    }
]

export const masterMacroPermission = [
    {
        id: "MASTER_MACRO_ADD",
        title: "Add Macro"
    },
    {
        id: "MASTER_MACRO_EDIT",
        title: "Edit Macro"
    },
    {
        id: "MASTER_MACRO_DELETE",
        title: "Delete Macro"
    }
]

export const accountDoctorPermission = [
    {
        id: "ACCOUNT_DOCTOR_ADD",
        title: "Add Doctor"
    },
    {
        id: "ACCOUNT_DOCTOR_EDIT",
        title: "Edit Doctor"
    },
    {
        id: "ACCOUNT_DOCTOR_DELETE",
        title: "Delete Doctor"
    },
    {
        id: "ACCOUNT_DOCTOR_PRICE",
        title: "Pricelist Doctor"
    }
]

export const accountUserPermission = [
    {
        id: "ACCOUNT_USER_ADD",
        title: "Add User"
    },
    {
        id: "ACCOUNT_USER_VIEW",
        title: "View User"
    },
    {
        id: "ACCOUNT_USER_EDIT",
        title: "Edit User"
    },
    {
        id: "ACCOUNT_USER_DELETE",
        title: "Delete User"
    },
    {
        id: "ACCOUNT_USER_ACCESS_RIGHT",
        title: "Access right User"
    }
]

export const accountSampleCollectorPermission = [
    {
        id: "ACCOUNT_SAMPLE_COLLECTOR_ADD",
        title: "Add sample collector"
    },
    {
        id: "ACCOUNT_SAMPLE_COLLECTOR_EDIT",
        title: "Edit sample collector"
    },
    {
        id: "ACCOUNT_SAMPLE_COLLECTOR_DELETE",
        title: "Delete sample collector"
    },
]

export const accountSignaturePermission = [
    {
        id: "ACCOUNT_SIGNATURE_ADD",
        title: "Add signature"
    },
    {
        id: "ACCOUNT_SIGNATURE_EDIT",
        title: "Edit signature"
    },
]